import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import GridSpacing from 'common/layout/grid-spacing/GridSpacing';
import { NavLink } from 'react-router-dom';
import {ErrorPageProps} from './ErrorPage.types'

import './ErrorPage.scss'

const ErrorPage = ({errorCode, errorStatus} : ErrorPageProps) : JSX.Element => {

  return (

    <GridSpacing>
      <div className="error">
        <div className="error__content">
          <RemotisGrid container className="error-content__container" >

            <RemotisGrid item xs={6} className="error__content__summary" >
              <div className="error__content__title">
                <span>{errorCode}</span>
                <h1>{errorStatus}</h1>
                <hr></hr>
              </div>
            </RemotisGrid>

            <RemotisGrid item xs={6} >
              <div  className="error__content__description">
                <div className="error__content__text">
                  <span>
                    OOPS! Something went wrong.
                    Try going back or go to  <NavLink to={'/'} className="go-home"> home page. </NavLink>
                  </span>
                </div>
              </div>
            </RemotisGrid>

          </RemotisGrid>
        </div>

      </div>
    </GridSpacing>
  );
};

export default ErrorPage;
