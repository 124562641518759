import {ChipProps} from "./RemotisChip.types"
import { Chip } from "@mui/material";
import "./RemotisChip.scss"

const RemotisChip = ({label, variant}:ChipProps): JSX.Element => {
  return (
    <div className='remotis-chip'>
      <Chip label={label} variant={variant} />
    </div>
  );
}

export default RemotisChip;