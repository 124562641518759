import { Hourglass, ScreenResolution, SteeringWheel, TurbineIcon, SpringExtensionIcon } from 'assets/icons/Icons';
import ToggleButtons from 'common/components/toggle-buttons/ToggleButtons';
import PositionSlider from 'common/components/position-slider/PositionSlider';
import { ConfigProviderMatcherProps } from './ConfigProviderMatcher.types'


const liveRace = [
  {
    component: PositionSlider,
    props:{
      title: "Vibration Level",
      values: [],
      key: 'mechanic.vibration.level',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 5,
      step: 0.01,
      marks:[{value:0, label:0}, {value:5, label:5}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Engine Min Power",
      values: [],
      key: 'mechanic.engine.power.min',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Engine Max Power",
      values: [],
      key: 'mechanic.engine.power.max',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Turbo",
      values: [],
      key: 'mechanic.engine.turbo',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "steering adjustment",
      values:  [],
      key: 'mechanic.steering.adjustment',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: -0.3,
      max: 0.3,
      step: 0.005,
      marks:[{value:0, label:0}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Steering PWM Min",
      values: [],
      key: 'mechanic.steering.pwm.min',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: -1,
      max: 0,
      step: 0.05,
      marks:[{value:-1, label:-1}, {value:0, label:0}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Steering PWM Max",
      values: [],
      key: 'mechanic.steering.pwm.max',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: 1,
      max: 2,
      step: 0.05,
      marks:[{value:1, label:1}, {value:2, label:2}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "steering calibration",
      key: 'mechanic.steering.calibration',
      icon: SteeringWheel,
      // for ranged sliders
      values: [],
      default: 50,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Intervention degree",
      key: 'mechanic.intervention.degree',
      icon: SteeringWheel,
      // for ranged sliders
      values: [],
      default: 50,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Traction control min power",
      key: 'mechanic.traction.control.power.min',
      icon: TurbineIcon,
      default: 50,
      // for ranged sliders
      values: [],
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "traction control adjustment",
      values: [],
      key: 'mechanic.traction.control.adjustment',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: ToggleButtons,
    props:{
      title: "Spring Extension",
      values: [1,2,3,4,5,6],
      key: 'mechanic.spring.extension',
      icon: SpringExtensionIcon,
      units: '%',
      id:'',
      min: 0,
      max: 100,
      value:'',
    }
  },
]

const mechanics = [
  {
    component: PositionSlider,
    props:{
      title: "Vibration Level",
      values: [],
      key: 'mechanic.vibration.level',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 5,
      step: 0.01,
      marks:[{value:0, label:0}, {value:5, label:5}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Engine Min Power",
      values: [],
      key: 'mechanic.engine.power.min',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Engine Max Power",
      values: [],
      key: 'mechanic.engine.power.max',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Turbo",
      values: [],
      key: 'mechanic.engine.turbo',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "steering adjustment",
      values:  [],
      key: 'mechanic.steering.adjustment',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: -0.3,
      max: 0.3,
      step: 0.005,
      marks:[{value:0, label:0}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Steering PWM Min",
      values: [],
      key: 'mechanic.steering.pwm.min',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: -1,
      max: 0,
      step: 0.05,
      marks:[{value:-1, label:-1}, {value:0, label:0}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Steering PWM Max",
      values: [],
      key: 'mechanic.steering.pwm.max',
      icon: SteeringWheel,
      id:'',
      value:'',
      min: 1,
      max: 2,
      step: 0.05,
      marks:[{value:1, label:1}, {value:2, label:2}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "steering calibration",
      key: 'mechanic.steering.calibration',
      icon: SteeringWheel,
      // for ranged sliders
      values: [],
      default: 50,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Intervention degree",
      key: 'mechanic.intervention.degree',
      icon: SteeringWheel,
      // for ranged sliders
      values: [],
      default: 50,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "Traction control min power",
      key: 'mechanic.traction.control.power.min',
      icon: TurbineIcon,
      default: 50,
      // for ranged sliders
      values: [],
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: PositionSlider,
    props:{
      title: "traction control adjustment",
      values: [],
      key: 'mechanic.traction.control.adjustment',
      icon: TurbineIcon,
      id:'',
      value:'',
      min: 0,
      max: 100,
      marks:[{value:0, label:0}, {value:100, label:100}]
    }
  },
  {
    component: ToggleButtons,
    props:{
      title: "Spring Extension",
      values: [1,2,3,4,5,6],
      key: 'mechanic.spring.extension',
      icon: SpringExtensionIcon,
      units: '%',
      id:'',
      min: 0,
      max: 100,
      value:'',
    }
  },
]

const video = [
  {
    component: ToggleButtons,
    props:{
      title: "Video Resolution",
      key: 'video.resolution',
      icon: ScreenResolution,
      units: 'p',
      default: 50,
      // for ranged sliders
      values: [640, 720 , 1080, 1440, 2160],
      id:'',
      value:''
    }
  },
  {
    component: ToggleButtons,
    props:{
      title: "Video frame rate acquisition",
      key: 'video.frame.rate.acquisition',
      icon: Hourglass,
      default: 50,
      // for ranged sliders
      values: [15, 30, 40, 60 , 90, 120],
      units: 'FPS',
      id:'',
      value:''
    }
  },
  {
    component: ToggleButtons,
    props:{
      title: "Video frame rate drop",
      key: 'video.frame.rate.drop',
      icon: Hourglass,
      default: 50,
      // for ranged sliders
      values: [15, 30, 40, 60 , 90, 120],
      units: 'FPS',
      id:'',
      value:''
    }
  },
  {
    component: ToggleButtons,
    props:{
      title: "Video bandwidth",
      key: 'video.bandwidth',
      icon: Hourglass,
      default: 50,
      // for ranged sliders
      values: [2, 4 , 6, 8, 12, 14, 16, 20, 24, 25, 28, 32, 36, 40, 44 ,48],
      units: 'MB/S',
      id:'',
      value:''
    }
  },
]

const getSettingsByType = ( type:string ) => {
  switch (type) {
  case 'mechanics':
    return mechanics;
  case 'video':
    return video;
  case 'Garage':
    return liveRace;
  default:
    return [];
  }
}

// combine api response saved configs details with client settings, based on the requested type of setting
export const configProviderMatcher = (
  apiConfigs:[Record<string, string>] | Response | undefined,
  type:string
) => {
  const mapped:ConfigProviderMatcherProps[] = [];
  if(apiConfigs && apiConfigs.constructor == Array) {
    const configs = getSettingsByType(type);
    configs.forEach((config) => {
      const apiConfig = apiConfigs.find(apiConfig => apiConfig.key === config.props.key);
      if(apiConfig) {
        const configCopy = config;
        configCopy.props = {...config.props, ...apiConfig}

        mapped.push(configCopy);
      }
    })
  }
  return mapped;
}