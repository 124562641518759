import { Box, Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { ArrowBack } from 'assets/icons/Icons';
import { SubMenuProps } from './SubMenu.types';
import './SubMenu.scss';

const SubMenu = ({ children, goBackPath }: SubMenuProps): JSX.Element => {
  return (
    <Fragment>
      {goBackPath && (
        <Link to={goBackPath}>
          <ArrowBack className="sub-menu-icon" />
        </Link>
      )}

      {children && (
        <div className="menu-shop">
          <Container>
            <Grid container>
              <Box className="menu-shop--box">
                <div className="menu-shop--box__elements">{children}</div>
              </Box>
            </Grid>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default SubMenu;
