import ThreeColumnsGrid from 'settings/components/three-columns-grid/ThreeColumnsGrid';
import IOSSwitch from 'common/components/switch/Switch';
import Flag from 'assets/images/Flag.png';
import './AccountManagement.scss';

const AccountManagement = () => {
  return (
    <div className="account-management">
      <ThreeColumnsGrid

        left={
          <>Account</>
        }

        middle={
          <div>
            <p>
              Username: <span className="body-text">David_2020</span>
            </p>
            <p>
              Full Name: <span className="body-text">David Marconi</span>
            </p>
            <p>
              <span className="body-text">david.marconi@gmail.com</span>
            </p>
            <p>
              Password: <span className="body-text">*********</span>
            </p>
            <p>
              Language: <span className="body-text">English</span>
            </p>
            <p>
              Country:
              <span className="body-text">
                <img src={Flag} className="account-management__country-flag-image" alt="user country flag"></img> Romania
              </span>
            </p>
            <p>
              Year: <span className="body-text">1989</span>
            </p>
            <p>
              Gender: <span className="body-text">Male</span>
            </p>
          </div>
        }

        right={
          <div>
            <p> Change Username</p>
            <p> Change Full Name</p>
            <p> Change Email</p>
            <p> Change Password</p>
            <p> Change Language</p>
            <p> Change Country</p>
            <p> Change Age</p>
            <p> Change Gender</p>
          </div>
        }
      />

      <ThreeColumnsGrid

        left={
          <>Notification</>
        }

        middle={
          <div>
            <p> Notifications </p>
            <p> Notifications while driving</p>
          </div>

        }

        right={
          <div>
            <div>
              <IOSSwitch/>
            </div>
            <div>
              <IOSSwitch/>
            </div>
          </div>
        }
      />

    </div>
  );
};

export default AccountManagement;
