
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { setConfig } from 'client-server-provider/api-service/ApiService'
import ApiLoader from 'client-server-provider/api-loader/ApiLoader'
import { ApiResponseProps } from 'client-server-provider/api-loader/ApiLoader.types'
import { useApiStateMutator } from 'client-server-provider/api-state-mutator/ApiStateMutator'
import { configProviderMatcher } from 'helpers/ConfigProviderMatcher'
import { useLocation } from 'react-router-dom'
import { useBrokerContext } from 'broker/Broker';
import { ApiPostRouteEnum, ApiGetRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';

import './CarSetupLayout.scss'

const CarSetupLayout = ({ apiResponse }: ApiResponseProps): JSX.Element => {
  const broker = useBrokerContext()
  const setApiState = useApiStateMutator(setConfig)
  const setSettings = (id:number | string, value:string | number | number[]) => {
    // custom hook for using react-query mutation hook with params
    console.log("Send data via Api")
    setApiState({
      data:{id:id, value:value},
      params:{id:id},
      key: ApiPostRouteEnum.setConfig,
    })

    if (broker && apiResponse && apiResponse.constructor == Array) {
      const found = apiResponse?.find((element) => element.id === id);
      if(found) {
        console.log("Send data via Broker")
        broker.sendData(JSON.stringify({
          [found.key]: value,
        }))
      }
    }
  }
  const {pathname} = useLocation();
  const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1)

  const configs = configProviderMatcher(apiResponse, currentPath);
  return (
    <div className="car-setup">
      <div className="car-setup__content">

        <RemotisGrid container className="car-setup__content__container" >
          {configs.map((config) => {
            return (
              <RemotisGrid item xs="auto" key={config.props.key} className="grid-item center">
                <config.component
                  {...config.props}
                  callback={setSettings}
                />
              </RemotisGrid>
            )
          })}
        </RemotisGrid>
      </div>
    </div>
  );
};

// wrapper(high order component) that provide api response based on method provided from api service
export default ApiLoader({
  Component: CarSetupLayout,
  queryKey: ApiGetRouteEnum.getConfig
});