import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { BoostProps } from './Boost.types'
import RemotisCircularProgress from "live-race/components/remotis-circular-progress/RemotisCircularProgress"
import rangeValueConverter from 'helpers/RangeValueConvertor';

import './Boost.scss'

const Boost = ({ boostNumber, outputPowerPercentage, turbo }: BoostProps): JSX.Element => {
  const barrelMax = 100;
  const barrelTimeMs = 100;
  const barrelDecrement = 10;
  const loaderMaxCharge = 50;

  const outputPowerPercentageMax = 10;
  const outputPowerPercentageMin = 0;

  const [progress, setProgress] = React.useState(boostNumber * barrelMax);
  const remainingTurbo = React.useRef<number | null>(null);

  const getValue = (index:number) => {
    const boostMax = index * barrelMax;

    // check how much boost is consumed from current barrel
    const currentBarrel = boostMax - progress;

    // check if the current progress is assigned to the current barrel
    if(currentBarrel <= 0)
      return barrelMax;

    if(currentBarrel > barrelMax)
      return 0;

    // remove from barrel consumed boost
    return barrelMax - currentBarrel
  }

  React.useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if(turbo) {
      timer = setInterval(() => {
        if(remainingTurbo.current === null) {
          // total boost is given by boosts number and the amount of boost in each barrel
          remainingTurbo.current = boostNumber * barrelMax
        }
        // decrease boost every time by amount given by admin
        remainingTurbo.current = remainingTurbo.current > barrelDecrement ? remainingTurbo.current - barrelDecrement : 0;
        setProgress(remainingTurbo.current);

      }, barrelTimeMs);
    }

    if(!turbo && timer) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [boostNumber, turbo]);

  return (
    <div className='boost'>
      <div className='boost__loaders'>
        <RemotisCircularProgress name='pwr' maxValue={loaderMaxCharge} value={rangeValueConverter(outputPowerPercentageMax, outputPowerPercentageMin, loaderMaxCharge, 0, outputPowerPercentage)}/>
        <p className='boost__loaders--description'>Boost each lap</p>
        {/* the value given to loader can be only in interval 0 - 50, and any other value range is translated into that value */}
        <RemotisCircularProgress name='asc' value={34} maxValue={loaderMaxCharge}/>
      </div>

      <div className='boost--barrels'>
        {Array.from(Array(boostNumber), (e, i) => {
          return <LinearProgress key={i} variant="determinate" value={getValue(i + 1)} />
        })}
      </div>
    </div>
  );
}

export default Boost;