interface gamepadAvailableActionsProps {
  [key: string]: {name:string}
}

export const gamepadAvailableActions:gamepadAvailableActionsProps = {
  'gamepad.control.speed': {
    name: 'Speed'
  },
  'gamepad.control.brake': {
    name: 'Brake'
  },
  'gamepad.control.turbo': {
    name: 'Turbo'
  },
  'gamepad.control.steering': {
    name: 'Steering'
  },
  'gamepad.control.headLights': {
    name: 'Head Lights'
  }
}