import React, { useState } from 'react';
import './RaceSlot.scss';
import Moment from 'moment';
import { RaceSlotProps } from './RaceSlot.types';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { raceTypes } from '../RaceTypes';

const types = raceTypes;

const RaceSlot = ({
  startDate,
  endDate,
  iconLicense,
  type,
  seatsLeft,
  grid,
  id,
}: RaceSlotProps): JSX.Element => {
  const [reserved, setReserved] = useState(false);
  const navigate = useNavigate();

  const clickHandler = () => {
    setReserved(!reserved);
    navigate({
      pathname: '/racing/reserve',
      search: createSearchParams({ date: Moment(startDate).format('yyyy-MM-DD HH:mm') }).toString(),
    });
  };

  const classRaceSlot = `race-slot ${types?.[type].class} ${
    reserved && types?.[type].classReserved
  }`;

  return (
    <button
      onClick={clickHandler}
      disabled={seatsLeft == 0 || (id !== types[type]?.id && id !== 'all')}
      className={classRaceSlot}
      id={id}
      style={{ gridRow: 'span' + ' ' + grid }}
    >
      <span>
        <span className="race-slot__interval">
          {Moment(startDate).format('HH:mm')} - {Moment(endDate).format('HH:mm')}
        </span>
        <p className="race-slot__description">
          {/* {types[type] && React.createElement(types[type]?.icon)} */}
          <span>{React.createElement(types[type]?.icon)}</span>
          {iconLicense && React.createElement(iconLicense)}
        </p>
      </span>
      {Number(seatsLeft) <= 4 && (
        <span className="race-slot__description--left">
          <span className="race-slot__description--left--nr">{seatsLeft}</span> LEFT{' '}
        </span>
      )}
      {grid >= 3 && (
        <span className="race-slot__sessions">
          Sessions
          <p>
            Free practice{' '}
            <span className="race-slot__sessions--minute">
              <span className="nr">30</span> min
            </span>
          </p>
          <p>
            Qualify{' '}
            <span className="race-slot__sessions--minute">
              <span className="nr">30</span> min
            </span>
          </p>
          <p>
            Race{' '}
            <span className="race-slot__sessions--minute">
              <span className="nr">30</span> min
            </span>
          </p>
        </span>
      )}
    </button>
  );
};

export default RaceSlot;
