import {
  SingleRaceIcon,
  FreePracticeIcon,
  SessionsEventIcon,
  TestDriveIcon,
} from 'assets/icons/Icons';

export const raceTypes = {
  fp: {
    class: 'fp',
    classReserved: 'fp-reserve',
    icon: FreePracticeIcon,
    id: 'free-practice',
    name: "Free Practice",
  },
  sr: {
    class: 'sr',
    classReserved: 'sr-reserve',
    icon: SingleRaceIcon,
    id: 'single-race',
    name: "Single Race",
  },
  ch: {
    class: 'ch',
    classReserved: 'ch-reserve',
    icon: SingleRaceIcon,
    id: 'championship',
    name: "Championship",
  },
  ev: {
    class: 'ev',
    classReserved: 'ev-reserve',
    icon: SessionsEventIcon,
    id: 'event',
    name: 'Event',
  },
  td: {
    class: 'td',
    classReserved: 'td-reserve',
    icon: TestDriveIcon,
    id: 'test-drive',
    name: 'Test Drive'
  },
};
