import Carousel from "nuka-carousel";
import { HudOptionsSelectorProps } from './HudOptionsSelector.types';

import "./HudOptionsSelector.scss";

const HudOptionsSelector = ({ rpm, position, lapNumber, currentLap }: HudOptionsSelectorProps): JSX.Element => {

  function numberWithCommas(x:string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div className="hud-options-selector">

      <p className="hud-options-selector--detail">{position}<sup>th</sup></p>

      <Carousel cellAlign={'center'} defaultControlsConfig={{
        nextButtonStyle: {
          display: 'none',
        },
        prevButtonStyle: {
          display: 'none',
        },
        pagingDotsContainerClassName: 'paging-dots-container',
        pagingDotsStyle: {
          display: 'block',
          fill: 'white',
          margin: '2px'
        }
      }}>
        <div className="hud-options-selector__content">
          <p> {rpm ? numberWithCommas(rpm.toFixed()) : 0} <span className="units"> RPM </span></p>
        </div>
        <div className="hud-options-selector__content">
          <p>Start</p>
        </div>
        <div className="hud-options-selector__content">
          <p>Stop</p>
        </div>
      </Carousel>
      <p className="hud-options-selector--detail">{currentLap}/{lapNumber}</p>
    </div>
  );
}

export default HudOptionsSelector;