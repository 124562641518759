export interface FoundedInteractionDetail {
  type: string,
  index: number,
  hash: string,
}

export function findGamepadInteraction(gamepads: (Gamepad | null)[], compareGamepad:(Gamepad | null)[]) {
  const foundedInteractionDetail: FoundedInteractionDetail = {
    'hash': '',
    'type': '',
    'index': -1
  };

  gamepads.forEach(function (item, gamepadIndex) {
    if(item) {
      const axisMovedPosition = item?.axes.findIndex((element, axisIndex) => {
        if(!compareGamepad[gamepadIndex]?.axes[axisIndex]) {
          return false;
        }
        return element !== 0 && element !== compareGamepad[gamepadIndex]?.axes[axisIndex]
      });

      const buttonMovedPosition = item?.buttons.findIndex((element) => element.pressed)

      if(buttonMovedPosition != -1) {
        foundedInteractionDetail.hash = String(gamepadIndex)
        foundedInteractionDetail.type = 'buttons'
        foundedInteractionDetail.index = buttonMovedPosition

        return;
      }

      if(axisMovedPosition != -1) {
        foundedInteractionDetail.hash = String(gamepadIndex)
        foundedInteractionDetail.type = 'axes'
        foundedInteractionDetail.index = axisMovedPosition

        return;
      }
    }
  });

  return foundedInteractionDetail;
}