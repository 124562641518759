import './SpecCar.scss';
import { SpecCarProps } from './SpecCar.types';

const SpecCar = ({ specTitle, description, image }: SpecCarProps): JSX.Element => {
  return (
    <div className="spec-car">
      <div>
        <div>
          <span className="spec-car__title"> {specTitle} </span>
        </div>
        <p className="spec-car__description"> {description} </p>
        <div className="spec-car__image">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SpecCar;
