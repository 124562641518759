import { NavLink } from 'react-router-dom';
import { ElementSubMenuProps } from './ElementSubMenu.types'
import './ElementSubMenu.scss'

const ElementSubMenu = ({ path, menuTab }: ElementSubMenuProps): JSX.Element => {

  return (
    <NavLink to={path} className={({ isActive }) => (isActive ? 'active' : '')}>
      <div className="element-sub-menu">
        <div className="element-sub-menu--custom-btn">{menuTab}</div>
      </div>
    </NavLink>
  );
};

export default ElementSubMenu;
