import './ProfileReserve.scss';
import { ProfileReserveProps } from './ProfileReserve.types';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import React from 'react';

const ProfileReserve = ({
  nr,
  name,
  img,
  status,
  license,
  flag,
}: ProfileReserveProps): JSX.Element => {
  return (
    <RemotisGrid item xs={4} className="profile-reserve--details">
      <div className="profile-reserve--nr">
        <span> {nr} </span>
      </div>
      <div className="profile-reserve--avatar">
        <img src={img} alt="avatar"></img>
        <div className="profile-reserve--license">{license && React.createElement(license)}</div>
        {status ? <div className="profile-reserve--available"></div> : <div></div>}
      </div>
      <div className="profile-reserve--name">
        {flag && React.createElement(flag)}
        <p>{name}</p>
      </div>
    </RemotisGrid>
  );
};

export default ProfileReserve;
