
import React from 'react';
import Slider from '@mui/material/Slider';
import { PositionSliderProps } from './PositionSlider.types'

import './PositionSlider.scss'

export default function PositionSlider({title, icon, id, callback, value, marks, min, max, step = 1} : PositionSliderProps) : JSX.Element {

  const handleChange = (event:  Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {

    if (callback) {
      callback(id, newValue);
    }

  };

  return (
    <div className="position-slider">
      {icon &&
          React.createElement(icon)
      }
      <p>{title}</p>
      <Slider
        key={`slider-${value}`}
        track={false}
        aria-labelledby="track-false-slider"
        defaultValue={Number(value)}
        onChangeCommitted={handleChange}
        step={step}
        marks={marks}
        min={min}
        max={max}
        valueLabelDisplay={'auto'}
      />
    </div>
  );
}

