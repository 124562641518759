import { NavLink } from 'react-router-dom';
import { GoogleIcon, FacebookIcon } from 'assets/icons/Icons';
import LayoutWallet from 'common/layout/wallet/LayoutWallet'
import logo from 'assets/images/Logo.png';
import './LoginMenu.scss';
import LoginContent from "../login-content/LoginContent";
import Profile from 'common/components/profile/Profile';
import { Navigate } from "react-router";
import { useGetIsLoggedIn ,
  XaliasLoginButton
} from 'common/libraries';
import { startingRoute } from 'routes'


const LoginMenu = (): JSX.Element => {
  const isLoggedIn = useGetIsLoggedIn();

  if (isLoggedIn) {
    return <Navigate to="/my-profile" />;
  }

  return (
    <div className="login">
      <Profile>
        <img src={logo} alt="" />
        <div className="login__header">
          <h1>Create Account</h1>
          <p>and have a free test drive</p>
        </div>

        <div className="login__form">
          <LayoutWallet/>

          <div className="login__form--social-icons">
            <p>Or continue with this social profile</p>
            <XaliasLoginButton
              loginButtonText='xAlias'
              data-testid='xAliasLoginBtn'
              className = "google-login-button"
              callbackRoute={startingRoute}
              nativeAuth={true}
            >
              <GoogleIcon/>
            </XaliasLoginButton>
            <button>
              <FacebookIcon/>
            </button>
          </div>
        </div>

        <div className="login__footer">
          <span> Already a member? <NavLink to={'/login'} className="login__form--link login__form--login"> Login </NavLink> </span>
        </div>

      </Profile>

      <div className="login__content">
        <LoginContent/>
      </div>

    </div>
  );
};

export default LoginMenu;
