import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import './SuccessPage.scss';

const SuccessPage = (): JSX.Element => {
  return (
    <div className="success">
      <div className="success-content">
        <RemotisGrid container alignItems="center" className="success-content__container" >

          <RemotisGrid item xs={5} className="success-content__summary" >
            <div className="success-content__title">
              <span>SUCCESS</span>
              <hr></hr>
            </div>
          </RemotisGrid>

          <RemotisGrid item xs={5} className="success-content__description" >
            <div className="success-content__text">
              <span>
                {'Your payment was received. Thank you! Your order is being processed and your Licence will reach your xPortal app shortly in \'My NFTs\' Tab. As soon as you have a Licence you can reserve your first race in the "Racing" tab.'}
              </span>
            </div>
          </RemotisGrid>

        </RemotisGrid>
      </div>
    </div>
  );
};

export default SuccessPage;
