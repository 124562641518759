
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import ApiLoader from 'client-server-provider/api-loader/ApiLoader'
import { ApiResponseProps } from 'client-server-provider/api-loader/ApiLoader.types'
import { useApiStateMutator } from 'client-server-provider/api-state-mutator/ApiStateMutator'
import { configProviderMatcher } from 'helpers/ConfigProviderMatcher'
import { useLocation } from 'react-router-dom'
import { RacingCar } from 'assets/icons/Icons';
import { ApiGetRouteEnum, ApiPostRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';

import SlidingDropDown from 'common/components/sliding-drop-down/SlidingDropDown';

import './CarAestheticLayout.scss'
import { setConfig } from 'client-server-provider/api-service/ApiService';

const data = [
  {
    title: 'RM8 prototype',
    value: 1
  },
  {
    title: 'RM9 prototype',
    value: 2,
  },
  {
    title: 'R5M prototype',
    value: 3,
  },
  {
    title: 'RS prototype',
    value: 4,
  },
];

const CarAestheticLayout = ({ apiResponse }: ApiResponseProps): JSX.Element => {

  const setApiState = useApiStateMutator(setConfig);
  const setSettings = (id:string | number, value:string | number | number[]) => {

    // custom hook for using react-query mutation hook with params
    setApiState({
      data:{id:id, value:value},
      params:{id:id},
      key: ApiPostRouteEnum.setConfig,
    })
  }
  const {pathname} = useLocation();
  const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1)

  const configs = configProviderMatcher(apiResponse, currentPath);

  return (
    <div className="car-setup">
      <div className="car-setup__content">

        <RemotisGrid container className="car-setup__content__container" >
          <RemotisGrid item xs="auto" className="grid-item center">
            <SlidingDropDown icon={RacingCar} title="Car Body Kit" callback={setSettings} id={65} data={data}/>
          </RemotisGrid>
        </RemotisGrid>
      </div>
    </div>
  );
};

// wrapper(high order component) that provide api response based on method provided from api service
export default ApiLoader({
  Component: CarAestheticLayout,
  queryKey: ApiGetRouteEnum.getConfig
});
