
export function videoAspectRatioChanger(videoId: string, ratio: number) {
  const video = document.getElementById(videoId) as HTMLVideoElement;

  if (!video)
    return

  const clientHeight = window.innerHeight;
  const videoWidth = video.videoWidth;
  const videoHeight = video.videoHeight;

  if(!isNaN(videoWidth) && !isNaN(videoHeight)) {
    const newRatio = (videoWidth/videoHeight) / (ratio / 9);
    video.style.height = (clientHeight / newRatio) + "px";
  }
}
