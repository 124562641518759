import licenseA from 'assets/images/svg/Amateur.svg';
import licenseS from 'assets/images/svg/Specialist.svg';
import licenseE from 'assets/images/svg/Expert.svg';
import licenseL from 'assets/images/svg/Legend.svg';
import LicenseCards from 'shop/components/license-card/LicenseCard';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';

const LayoutCardLicenseCard = () => {
  return (
    <>
      <RemotisGrid item md={6}>
        <LicenseCards
          status={false}
          licenseLevel="Amateur"
          monthlyRcoins={35}
          maxPower={12000}
          logoLicense={licenseA}
          fps={60}
          boostEachLap={1}
          carLiveries={1}
          pricePerBattery={1}
          pricePerMonth={5}
        />
      </RemotisGrid>

      <RemotisGrid item md={6}>
        <LicenseCards
          status={false}
          licenseLevel="Specialist"
          monthlyRcoins={125}
          maxPower={20000}
          logoLicense={licenseS}
          fps={60}
          boostEachLap={2}
          carLiveries={2}
          pricePerBattery={2}
          pricePerMonth={10}
        />
      </RemotisGrid>

      <RemotisGrid item md={6}>
        <LicenseCards
          status={false}
          licenseLevel="Expert"
          monthlyRcoins={225}
          maxPower={25000}
          logoLicense={licenseE}
          fps={90}
          boostEachLap={3}
          carLiveries={3}
          pricePerBattery={3}
          pricePerMonth={15}
        />
      </RemotisGrid>

      <RemotisGrid item md={6}>
        <LicenseCards
          status={false}
          licenseLevel="Legend"
          monthlyRcoins={450}
          maxPower={30000}
          logoLicense={licenseL}
          fps={90}
          boostEachLap={4}
          carLiveries={4}
          pricePerBattery={4}
          pricePerMonth={20}
        />
      </RemotisGrid>
    </>
  );
};

export default LayoutCardLicenseCard;
