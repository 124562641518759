import LinearProgress from '@mui/material/LinearProgress';
import { ButtonsLoadedValueProps } from "./ButtonsLoadedValue.types"
import { styled } from '@mui/material/styles';

import "./ButtonsLoadedValue.scss"

const FastLinearProgress = styled(LinearProgress)({
  "& .MuiLinearProgress-bar": {
    // apply a new animation-duration to the `.bar` class
    animationDuration: "0s",
    transition: "none"
  }
});

const ButtonsLoadedValue = ({value}:ButtonsLoadedValueProps): JSX.Element => {
  return (
    <div className='linear-loader'>
      <FastLinearProgress value={value} className='linear-loader__loader' variant="determinate" />
    </div>
  );
}

export default ButtonsLoadedValue;