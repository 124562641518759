import './ReserveSlotLayout.scss';
import ApiLoader from 'client-server-provider/api-loader/ApiLoader';
import CardBox from 'common/components/card-box/CardBox';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Moment from 'moment';
import ReserveDetails from '../../components/reserve-details/ReserveDetails';
import ProfileReserve from '../../components/profile-reserve/ProfileReserve';
import Profile from 'assets/images/Profile.png';
import RemotisTextField from 'common/components/remotis-text-field/RemotisTextField';
import RemotisButton from 'common/components/remotis-button/RemotisButton';
import React, { useState } from 'react';
import { ArrowBack, LicenseAmateur, RomaniaFlag } from 'assets/icons/Icons';
import { ApiGetRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';

const ReserveSlot = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIpAddress(e.target.value);
  };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const date = Moment(searchParams.get('date')).format('yyyy-MM-DD HH:mm:ss');
    const params = { ip: ipAddress, date: date};

    navigate({
      pathname: '/Garage',
      search: `?${createSearchParams(params)}`,
    });
  };

  const getDate = searchParams.get('date');
  const test = Moment(getDate).format('yyyy-MM-DD HH:mm:ss');

  const WithRaceSlotsApi = ApiLoader({
    Component: ReserveDetails,
    queryKey: ApiGetRouteEnum.getRaceCalendar,
    params: { expand: 'license, raceSessions', start: test },
  });
  const profiles = [
    { name: 'David', img: Profile, nr: 1, status: true, logo: LicenseAmateur, flag: RomaniaFlag },
    { name: 'Ion', img: Profile, nr: 2, status: false, logo: LicenseAmateur, flag: RomaniaFlag },
    { name: 'Empty slot', img: Profile, nr: 3, status: false},
    { name: 'Empty slot', img: Profile, nr: 4, status: false },
    { name: 'Empty slot', img: Profile, nr: 5, status: false },
    { name: 'Empty slot', img: Profile, nr: 6, status: false },
    { name: 'Empty slot', img: Profile, nr: 7, status: false },
    { name: 'Empty slot', img: Profile, nr: 8, status: false },
    { name: 'Empty slot', img: Profile, nr: 9, status: false },
    { name: 'Empty slot', img: Profile, nr: 10, status: false },
    { name: 'Empty slot', img: Profile, nr: 11, status: false },
    { name: 'Empty slot', img: Profile, nr: 12, status: false },
  ];
  return (
    <div className="reserve-slot">
      <RemotisGrid container>
        <Link to="/racing/reserve">
        </Link>
        <RemotisGrid item>
          <CardBox>
            <div className='reserve-slot__container'>

              <Link to="/racing/calendar">
                <ArrowBack className="sub-menu-icon" />
              </Link>

              <h2 className='reserve-slot--header'>RESERVE SLOT</h2>
              <WithRaceSlotsApi />
              <RemotisGrid container className="reserve-slot__seats">
                {profiles.map((profile, key) => {
                  return (
                    <ProfileReserve
                      key={key}
                      nr={profile.nr}
                      name={profile.name}
                      img={profile.img}
                      status={profile.status}
                      license={profile.logo}
                      flag={profile.flag}
                    />
                  );
                })}
              </RemotisGrid>
              <div className="reserve-slot__connect">
                <RemotisTextField
                  id="standard-basic"
                  label="IP Address"
                  variant="standard"
                  onChange={handleChange}
                />
                <RemotisButton onClick={handleClick}> Connect to car </RemotisButton>
              </div>
            </div>
          </CardBox>
        </RemotisGrid>
      </RemotisGrid>
    </div>
  );
};

export default ReserveSlot;
