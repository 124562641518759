
function rangeValueConverter(oldMax:number, oldMin:number, newMax:number, newMin:number, oldValue:number) {

  if (oldMin === newMin && oldMax === newMax)
    return oldValue

  const oldRange = (oldMax - oldMin)
  const newRange = (newMax - newMin)
  const newValue = (((oldValue - oldMin) * newRange) / oldRange) + newMin


  // check for overflow values
  if(oldMax > 0 && oldMax < oldValue ) {
    return newMax;
  }

  return newValue;

}

export default rangeValueConverter;
