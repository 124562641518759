import './RankingLayout.scss';
import React from 'react';
import { Box, Container } from '@mui/system';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import RankingSlot from 'ranking/components/ranking-slot/RankingSlot';
import { SingleRaceIcon, Trophy, Flag, Medal } from 'assets/icons/Icons';

const RankingLayout = () => {
  return (
    <RemotisGrid container>
      <div className="ranking-layout">
        <Container>
          <RemotisGrid container>
            <Box className="ranking-layout__box">
              <div className='activeBtn'> {React.createElement(SingleRaceIcon)}</div>
              <div > {React.createElement(SingleRaceIcon)}</div>
              <div > {React.createElement(SingleRaceIcon)}</div>
              <div > {React.createElement(SingleRaceIcon)}</div>
              <div > {React.createElement(Flag)}</div>
              <div > {React.createElement(SingleRaceIcon)}</div>
              <div > {React.createElement(Trophy)}</div>
              <div > {React.createElement(Medal)}</div>
              <div > {React.createElement(SingleRaceIcon)}</div>
            </Box>
          </RemotisGrid>
        </Container>
      </div>
      <RemotisGrid container>
        <RankingSlot />
      </RemotisGrid>
    </RemotisGrid>
  );
};

export default RankingLayout;
