import { createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import rangeValueConverter from 'helpers/RangeValueConvertor'
import { useWebRtcContext } from 'webrtc/WebRtcConnection';
import { GamepadProps, GamepadContextProps, GamepadActions} from './Gamepad.types'
import { useQuery } from 'react-query';
import { getConfig } from 'client-server-provider/api-service/ApiService'
import { useNavigate } from "react-router-dom";
import { Modal } from '@mui/material';
import RemotisButton from 'common/components/remotis-button/RemotisButton'
import { ConfigNamesEnum, ConfigControlEnum } from 'local-constants/configs/config.enums';

const GamepadContext = createContext<GamepadContextProps | null>(null);

function Gamepad<P>(
  // Then we need to type the incoming component.
  // This creates a union type of whatever the component
  // already accepts AND our extraInfo prop
  Component: React.ComponentType<P & GamepadProps>
)
{
  const WithGamepad = (props: P) => {
    const [gamepads, setGamepads] = useState<(Array<Gamepad | null>)>([]);

    const [missingConfig, setMissingConfig] = useState(false);
    const navigate = useNavigate();
    const requestRef = useRef<number>(0);

    // config from api should only be set at init, invalidate re-render when config in backend changes
    // avoid changing configs in the middle of the race
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataRef = useRef<any[]| null>(null);
    const { data, isError, isLoading, error } =  useQuery("config-gamepad", () =>
      getConfig({key: 'gamepad.control'}), {
    });

    if(error) {
      console.log('error',error)
    }
    if(isLoading) {
      console.log('isLoading',isLoading)
    }
    if(isError) {
      console.log('isError', isError)
    }

    /**
     * Current controls value that are sent to the child component
     * This is not a syncronous function and connot be used to test the previos values
     */
    const [gamepadControls, setGamepadControls] = useState({
      [ConfigNamesEnum.speed]: 0,
      [ConfigNamesEnum.brake]: 0,
      [ConfigNamesEnum.steering]: 0.5,
      [ConfigNamesEnum.turbo]: 0,
      [ConfigNamesEnum.headLights]: 0
    });

    /**
     * Ref that will be compared to the gamepadControls
     * This will be used to check if the gamepad values are the same or not
     */
    const prevGampadValuesRef = useRef({
      [ConfigNamesEnum.speed]: 0,
      [ConfigNamesEnum.brake]: 0,
      [ConfigNamesEnum.steering]: 0.5,
      [ConfigNamesEnum.turbo]: 0,
      [ConfigNamesEnum.headLights]: 0
    });

    const convertNumberToThreeDecimalPlaces = (number: number) => {
      return Math.round(number * 1000) / 1000
    }

    const webrtc = useWebRtcContext()

    // this will keep all the gamepad timestampRef that is modified only when a change in gamepad is made
    const timestampRef = useRef<Array<number | undefined>>([]);

    // requestAnimationFrame id (needed in case of cancel the requestFrame)
    const frameRef = useRef(null);

    /**
     * Function that will mapp the values from gamepad to predifined gamepadControls
     * This will check all available actions based on config path from the API
     * @param gamepads
     * @returns currentGamepadControlsValues
     */
    const getCurrentCollectionButtonMap = (gamepads:Array<Gamepad | null>) => {
      const missingConfig:Array<string> = [];

      // init value for the available actions
      const currentGamepadControlsValues:GamepadActions = {
        [ConfigNamesEnum.speed]: 0,
        [ConfigNamesEnum.brake]: 0,
        [ConfigNamesEnum.steering]: 0,
        [ConfigNamesEnum.turbo]: 0,
        [ConfigNamesEnum.headLights]: 0
      };

      // forEach gamepadAvailableAction get the current value from the gamepad
      Object.values(ConfigNamesEnum).forEach((gamepadAvailableAction) => {
        // search in loaded config(from API) current control path
        const currentConfig = data?.find((el: { key: string; }) => el.key === gamepadAvailableAction)

        // when a key from gamepadAvailableAction is missing in the backend
        // this will trigger an error and user cannot go to live race until all the configs are done
        if(!currentConfig) {
          missingConfig.push(gamepadAvailableAction);
          return gamepadControls;
        }

        // current action will have a selected gamepad id
        // when gamepad with selected id is missing the user will need to config the correct gamepad to go live
        const foundedGamepadByHash = gamepads.find((gamepad, gamepadIndex) => String(gamepadIndex) === currentConfig.hash);

        if(!foundedGamepadByHash) {
          setMissingConfig(true)

          return gamepadControls;
        }

        // get from gamepad the value for current action
        const result = currentConfig.value.split('][').reduce(function(o: { [x: string]: unknown; }, k:string) {
          const formattedValue = k.replace(/[\\[\]]/gi, '');

          return o && o[formattedValue];
        }, foundedGamepadByHash);

        // set in currentGamepadControlsValues the value from gamepad
        if(gamepadAvailableAction in gamepadControls) {
        // google doc for axes
        // Each entry in the array is a floating point value in the range 1.0 – (-1.0) representing the axis position from the lowest value (-1.0) to the highest value (1.0).
        // keep all axes between 0-1
          // currentGamepadControlsValues[gamepadAvailableAction as keyof typeof currentGamepadControlsValues] = currentConfig.value.includes("axes") ? convertNumberToThreeDecimalPlaces(rangeValueConverter(-1, 1, 1, 0, result)) : convertNumberToThreeDecimalPlaces(result);
          currentGamepadControlsValues[gamepadAvailableAction as keyof typeof currentGamepadControlsValues] = convertNumberToThreeDecimalPlaces(result);
        }
      })

      // test if all the action have been set
      if(missingConfig.length !== 0) {
        setMissingConfig(true)

        return currentGamepadControlsValues;
      }

      return currentGamepadControlsValues;
    }

    const handleGamepadAction = ((gamepads:Array<Gamepad | null>) => {
      // get from selected gamepad current values
      const currentGamepadControlsValues = getCurrentCollectionButtonMap(gamepads);

      if (!currentGamepadControlsValues)
        return

      // select from API if the gamepad should be reversed or not
      const gamepadReversedApi = data?.find((el: { key: string; }) => el.key === ConfigControlEnum.throttleReversed)
      const gamepadReversed = gamepadReversedApi?.value === 'true' ? -1 : 1;

      // set the values and send them via data channel only if the values have changed
      if(JSON.stringify(currentGamepadControlsValues) !== JSON.stringify(prevGampadValuesRef.current)) {
        prevGampadValuesRef.current = currentGamepadControlsValues;
        setGamepadControls({...currentGamepadControlsValues})

        // send action via simple peer
        if (webrtc) {
          webrtc.simplePeerSendData({
            "mechanic.engine.speed":  (currentGamepadControlsValues[ConfigNamesEnum.brake] - currentGamepadControlsValues[ConfigNamesEnum.speed]) * gamepadReversed,
            "mechanic.engine.steering": currentGamepadControlsValues[ConfigNamesEnum.steering],
            "mechanic.engine.turbo.init": currentGamepadControlsValues[ConfigNamesEnum.turbo],
            "lights.headLights": currentGamepadControlsValues[ConfigNamesEnum.headLights],
          })
        }
      }
    })

    const goToSettings = () => {
      setMissingConfig(false);
      navigate("/settings/game-devices/controller");
    }

    const runAnimation = (() => {

      const gamepadsNow = window.navigator.getGamepads();
      const gamepadMoved = gamepadsNow.some(
        (e, index) => (e?.timestamp && Number(timestampRef.current[index]) < e?.timestamp) ||
        timestampRef.current.length === 0
      )

      const connectedGamepads = gamepadsNow.filter(n => n).length;
      if (connectedGamepads && gamepadMoved) {
        handleGamepadAction(gamepadsNow);
        timestampRef.current = gamepadsNow.map(gamepad => gamepad?.timestamp) ?? [];
      }

    })

    const connect = ((e:Event | GamepadEvent) => {
      const availableGamepads = window.navigator.getGamepads();
      if (e instanceof GamepadEvent) {
        console.log(
          "Gamepad connected at index %d: %d buttons, %d axes.",
          e.gamepad.index, e.gamepad.buttons.length, e.gamepad.axes.length
        );
      }

      setGamepads(availableGamepads);
      // reset timestampRef on each connect
      // when new device is connected we need to add the new timestamps for the new one
      // this will call run animation and update with the new timestampRef for each device
      timestampRef.current = [];

      const connectedGamepad = availableGamepads.filter(n => n).length;
      const intervalTime = data?.find((el: { key: string; }) => el.key === ConfigControlEnum.interval)
      console.log(intervalTime, connectedGamepad, requestRef.current)
      if(!requestRef.current && connectedGamepad && intervalTime) {
        console.log(intervalTime)
        requestRef.current = window.setInterval(() => {
          runAnimation();
        }, intervalTime ? Number(intervalTime.value) : 10);
      }
    })

    const disconnect = useCallback((e:Event | GamepadEvent) => {
      if (e instanceof GamepadEvent) {
        console.log("Gamepad disconnected from index %d.", e.gamepad.index);

        const gamepadsNow = window.navigator.getGamepads();
        setGamepads(gamepadsNow);

        // check if there are any connected gamepads by removing the null value from array
        // gamepad have by default 4 gamepads with null when not connected
        const connectedGamepad = gamepadsNow.filter(n => n).length;

        // cancel interval only when all gamepads have been disconnected
        if(connectedGamepad === 0 && frameRef.current !== null) {
          window.clearInterval(frameRef.current);
        }
      }
    }, [])

    const removeInterval = (() => {
      if(frameRef.current !== null) {
        window.clearInterval(frameRef.current);
      }
    })

    useEffect(() => {
      connect(new Event("init"));
      window.addEventListener("gamepadconnected", connect);
      window.addEventListener("gamepaddisconnected", disconnect);

      return () => {
        // remove interval if gamepad still connected and user go to other components
        removeInterval();

        // add listener to use interaction with gamepad
        window.removeEventListener("gamepadconnected", connect);
        window.removeEventListener("gamepaddisconnected", disconnect);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
      <>
        <Modal
          open={missingConfig && !isLoading && isError}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div className="basic-modal--title">
              Car settings have not been made, please go to settings and provide all actions
              <div className="basic-modal--buttons">
                <RemotisButton onClick={goToSettings}> Go to settings</RemotisButton>
              </div>
            </div>
          </div>
        </Modal>
        <Component controls = {gamepadControls} gamepad = {gamepads} {...props} />
      </>
    );
  }
  return WithGamepad;
}

export default Gamepad;

export const useGamepadContext = () => {
  return useContext(GamepadContext)
}

