import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import './Support.scss';

const SupportLayout = () => {
  return (
    <div className="support-layout">
      <RemotisGrid container>
        <RemotisGrid item>
          <iframe
            title="discord"
            src="https://e.widgetbot.io/channels/1072109755302162535/1072109755818070088"
            height="100%"
            width="100%"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </RemotisGrid>
      </RemotisGrid>
    </div>
  );
};

export default SupportLayout;
