import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import RaceSlot from '../race-slot/RaceSlot';
import Moment from 'moment';
import './RaceSlotsApi.scss';
import { RaceSlotProps } from '../race-slot/RaceSlot.types';
import { FoundApiProps, RaceSlotApiProps } from './RaceSlotsApi.types';
import { components } from 'remotisApi.types';
const RaceSlotsApi = (props : RaceSlotApiProps): JSX.Element => {
  const {apiResponse, ...calendarProps} = props;

  const intervals: RaceSlotProps[] = [];
  const format = 'yyyy-MM-DD HH:mm:ss';

  const startDate = Moment({
    day: Number(calendarProps?.numberDay),
    month: Number(calendarProps?.numberMonth) - 1,
    hour: 0,
    minute: 0
  });

  const nextDay = Moment({
    day: Number(calendarProps?.numberDay),
    month: Number(calendarProps?.numberMonth) - 1,
    hour: 0,
    minute: 0
  }).add(1, 'd');

  while (startDate.isBefore(nextDay)) {
    let slotDuration = 30;
    let seatsLeft = 0;
    const current = startDate.format(format);

    if (apiResponse && apiResponse.constructor == Array) {
      const found = apiResponse.find(
        (element: components['schemas']['RaceSlot']) => element.start == current
      );

      if (found) {
        const foundEnd = Moment(found?.end);
        const foundStart = Moment(found?.start);
        slotDuration = Moment.duration(foundEnd.diff(foundStart)).asMinutes();
        seatsLeft = Number(found.seats_left);
      }
    }

    const found: FoundApiProps = {
      startDate: startDate.clone(),
      endDate: startDate.add(slotDuration, 'm').clone(),
      type: 'fp',
      grid: slotDuration / 30,
      seatsLeft: seatsLeft,
      id: 'free-practice',
    };

    intervals.push(found);
  }
  return (
    <RemotisGrid container className="race-slots-api" direction="column">
      {intervals.map((interval, key) => (
        <RaceSlot
          id={calendarProps.filter ? String(calendarProps.filter) : ''}
          key={key}
          startDate={interval.startDate}
          endDate={interval.endDate}
          grid={interval.grid}
          type={interval.type}
          seatsLeft={interval.seatsLeft}
        />
      ))}
    </RemotisGrid>
  );
};

export default RaceSlotsApi;
