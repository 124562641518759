import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AuthenticatedRoutesWrapper , DappProvider , ToastList } from 'common/libraries';
import LoginMenu from 'login/layout/login-menu/LoginMenu';
import { RequireAuth } from './RequireAuth';
import { GridSpacedRoutes } from './GridSpacedRoutes';
import { Fragment } from 'react';
import { routes, startingRoute } from 'routes'
import NotFound from 'not-found/NotFound';
import { QueryClient, QueryClientProvider } from "react-query";
import WebRtcConnection from 'webrtc/WebRtcConnection';
import LiveRace from 'live-race/layout/live-race/LiveRace';
import BrokerConnection from 'broker/Broker';

// try to fix simple peer process
// worked only after installed
// npm i -S process
// npm i -S process-nextick-args
import * as process from 'process';
window['process'] = process;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    solid: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    // tablet: true;
    // laptop: true;
    // desktop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
      xxl: 2600,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10,
    },
  },
});

function App() {
  // @TODO move to config
  const apiTimeout = 6000;
  const walletId = String(import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID);
  const environment = import.meta.env.VITE_DAPP_PROVIDER_ENVIRONMENT;

  return (
    <StyledEngineProvider injectFirst>

      <ThemeProvider theme={theme}>

        <BrowserRouter basename={import.meta.env.BASE_URL}>

          <DappProvider
            environment={environment}
            customNetworkConfig={{
              name: 'customConfig',
              apiTimeout,
              walletConnectV2ProjectId: walletId,
            }}
            dappConfig={{
              shouldUseWebViewProvider: true,
            }}
          >
            <AuthenticatedRoutesWrapper
              routes={[]}
              unlockRoute="/login"
            >
              <QueryClientProvider client={queryClient}>
                <BrokerConnection>
                  <WebRtcConnection>
                    <Routes>
                      <Route path="/" element={<Navigate to={startingRoute} />}/>
                      <Route path="/login" element={<LoginMenu />} />
                      <Route path="/*" element={<NotFound />} />
                      <Route element={<RequireAuth />} >/
                        <Route path="/garage" element={<LiveRace />} />

                        <Route element={<GridSpacedRoutes />} >
                          {routes.map((route, key) => {
                            const basePath = route.basePath;
                            return route.baseComponent ?

                              <Route key={key} element={<route.baseComponent basePath={basePath} routes={route.paths} />} >
                                {route.paths.map((childRoute) => {
                                  return <Route key={key} path={`${basePath}/${childRoute.path}`} element={<childRoute.component />} />
                                })}
                              </Route>
                              :
                              <Fragment key={key}>
                                {route.paths.map((childRoute) => {
                                  return <Route key={key} path={`${basePath}/${childRoute.path}`} element={<childRoute.component />} />
                                })}
                              </Fragment>
                          })}
                        </Route>


                      </Route>
                    </Routes>
                  </WebRtcConnection>
                </BrokerConnection>
              </QueryClientProvider>
            </AuthenticatedRoutesWrapper>
            <ToastList />
          </DappProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

