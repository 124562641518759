import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButtonsProps } from './ToggleButtons.types'

import './ToggleButtons.scss'

const defaultProps = {
  verticalAlign: 'baseline',
}

export default function ToggleButtons({values, id, title, icon, units, verticalAlign, value, callback} : ToggleButtonsProps) : JSX.Element {

  const [currentStateValue, setCurrentStateValue] = React.useState<number | string>(value);


  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    currentValue: string,
  ) => {
    setCurrentStateValue(currentValue);
    if (currentValue !== null) {
      if (callback) {
        callback(id, currentValue);
      }

    }
  };

  return (
    <div className='toggle-buttons'>
      <div className='toggle-buttons__container'>

        {icon &&
          React.createElement(icon)
        }

        <p>{title}</p>
        <ToggleButtonGroup
          value= {String(currentStateValue)}
          onChange = {handleChange}
          exclusive = {true}
        >

          {values?.map((value, key) => {
            return (
              <ToggleButton value={String(value)} key={String(value) + key}>

                <span className='toggle-buttons--selector'>
                  <span className='toggle-buttons--text'>
                    {value}
                    <span style={{ verticalAlign: verticalAlign}} className='toggle-buttons--text--units'> { units } </span>
                  </span>
                </span>

              </ToggleButton>
            )
          })}

        </ToggleButtonGroup>
      </div>
    </div>
  );
}

ToggleButtons.defaultProps = defaultProps;