import { useState } from 'react';
import { getTransactions , useGetAccount, useGetNetworkConfig , getInterpretedTransaction } from 'common/libraries';
import { TransactionsPropsType, TransactionsCustomPropsType } from './PaymentAndBilling.types';

export const useGetTransactions = (payload?: TransactionsPropsType) => {
  const { address } = useGetAccount();
  const {
    network: { apiAddress, explorerAddress }
  } = useGetNetworkConfig();

  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<TransactionsCustomPropsType[]>([]);
  // @TODO move to config
  const apiTimeout = 6000;
  const transactionSize = 10;

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);

      const { data } = await getTransactions({
        apiAddress,
        receiver: address,
        condition: 'must',
        withScResults: true,
        withUsername: true,
        transactionSize,
        apiTimeout,
        ...(payload ?? {})
      });

      const interpretedTransactions = data.map((transaction) =>
        getInterpretedTransaction({ transaction, address, explorerAddress })
      );
      setTransactions(interpretedTransactions);
    } catch (error) {
      console.error('Failed to fetch transactions', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, transactions, getTransactions: fetchTransactions };
};
