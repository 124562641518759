import { useRef, useState, createContext, useContext } from "react";
import SimplePeer from 'simple-peer';
import { WebRtcConnectionContextProps, WebRtcConnectionProps, SimplePeerDataProps } from './WebRtcConnection.types'

const WebRtcContext = createContext<WebRtcConnectionContextProps | null>(null);

const WebRtcConnection = ({ children }: WebRtcConnectionProps): JSX.Element => {

  const [loading, setLoading] = useState(0);
  const [data, setData] = useState({
    "live-data": {
      "rpm": 0,
      "outputPowerPercentage": 0,
      "sockSensor": 0.0
    },
    "esc": {
      "outputVoltage": 0,
      "inputVoltage": 0,
      "temp": 0
    }
  });

  const [healthCheckIntervalId, setHealthCheckInterval] = useState<NodeJS.Timeout>();
  const frontCameraStreamRef = useRef(new MediaStream());
  const rearCameraStreamRef = useRef(new MediaStream());
  const simplePeer = useRef<SimplePeer.Instance | null>(null);
  const websocket = useRef<WebSocket | null>(null);

  const handleReceivingTrack = (track:MediaStreamTrack, mid:string | null) => {
    console.log('handleReceivingTrack, mid=', mid, track)
    // add first stream to the front camera video element
    const frontCameraVideo = document.getElementById('frontCamera') as HTMLVideoElement;
    if (mid === '0' || mid === 'video0') {
      frontCameraStreamRef.current.addTrack(track)
    }

    if (mid === '1' || mid === 'audio1' || mid === 'audio2') {
      frontCameraStreamRef.current.addTrack(track)
    }

    // if (frontCameraStreamRef.current.getVideoTracks().length > 0) {
    if (frontCameraStreamRef.current.getVideoTracks().length > 0 && frontCameraStreamRef.current.getAudioTracks().length > 0) {
      frontCameraVideo.srcObject = frontCameraStreamRef.current;
      frontCameraVideo.play();

      frontCameraVideo.onloadedmetadata = function() {
        setLoading(100)
      };
    }

    // add second stream to the rear camera video element
    const rearCameraVideo = document.getElementById('rearCamera') as HTMLVideoElement;
    if (mid === '2' || mid === 'video1') {
      rearCameraStreamRef.current.addTrack(track)
      rearCameraVideo.srcObject = rearCameraStreamRef.current;
      rearCameraVideo.play();
    }

  }

  const wsOnMessage = (event: { data: string; }) => {
    let msg;

    try {
      msg = JSON.parse(event.data);
    } catch (e) {
      console.log('INVALID SIGNAL RECEIVED')
      return;
    }
    console.log('SIGNAL RECEIVED', msg)
    if(simplePeer.current) {
      simplePeer.current.signal(msg)
    }
  }

  const simplePeerSendData=(data: SimplePeerDataProps ) => {
    if (simplePeer.current && simplePeer.current.connected) {
      simplePeer.current.send(JSON.stringify(data));
    }
  }

  const healthCheck=() => {
    if (simplePeer.current && simplePeer.current.connected) {
      simplePeer.current.send(JSON.stringify({"healthCheck": 1}));
    }
  }

  const sleep = (ms:number | undefined) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const createPeer = async (ip:string, powerType:string | null = null) => {

    setLoading(0)
    // trigger useEffect and recreate ws and peer if connection was already made
    if ((websocket.current != null && simplePeer.current != null)) {
      window.removeEventListener("message", wsOnMessage);

      websocket.current.close();
      simplePeer.current.destroy();

      websocket.current = null;
      simplePeer.current = null;

      if (powerType === 'close') {
        return;
      }
      await sleep(250);
    }

    frontCameraStreamRef.current = new MediaStream();
    rearCameraStreamRef.current = new MediaStream();

    websocket.current = new WebSocket(`wss://${ip}:8443/ws`);
    websocket.current.addEventListener("message", wsOnMessage);

    let timeToConnect = 0;
    while (websocket.current.readyState !==  websocket.current.OPEN) {
      await sleep(1);
      ++timeToConnect;
    }

    setLoading(40)
    console.log('The websocket.current took ' + timeToConnect + ' milliseconds to connect.');

    simplePeer.current = new SimplePeer({
      initiator: import.meta.env.VITE_WEBRTC_INITIATOR === 'true',
    });

    // set one Transceiver for every media
    simplePeer.current.addTransceiver('video', { direction: 'recvonly' });
    simplePeer.current.addTransceiver('audio', { direction: 'recvonly' });
    simplePeer.current.addTransceiver('video', { direction: 'recvonly' });

    simplePeer.current.on('signal', data => {
      console.log('SIGNAL SENT', data)
      if( websocket.current) {
        websocket.current.send(JSON.stringify(data))
      }
      setLoading(67);
    })

    simplePeer.current.on('connect', () => {
      console.log('CONNECT')
      const intervalId = setInterval(healthCheck, 500);
      setHealthCheckInterval(intervalId);
    })

    simplePeer.current.on('close', () => {
      setLoading(0)
      console.log('DISCONNECTED');
      clearInterval(healthCheckIntervalId)
    })

    simplePeer.current.on('track', (track:MediaStreamTrack, stream:MediaStream[], transceiver:RTCRtpTransceiver) => {
      setLoading(80)
      handleReceivingTrack(track, transceiver.mid)
    })

    simplePeer.current.on('data', receivedData => {
      const received = JSON.parse(receivedData);
      setData(prevState => {
        const current = {...prevState, ...received};
        if(JSON.stringify(prevState) === JSON.stringify(current)) {
          return prevState;
        }

        return current;
      });

    })
  }

  return (
    <WebRtcContext.Provider value={{createPeer, loading, data, simplePeerSendData}}>{children}</WebRtcContext.Provider>
  );
}

export default WebRtcConnection;

export const useWebRtcContext = () => {
  return useContext(WebRtcContext)
}