import Modal from '@mui/material/Modal';
import './BasicModal.scss';
import { Button } from '@mui/material';
import { BasicModalProps } from './BasicModal.types';
import { logout } from 'common/libraries';

export default function BasicModal({ isOpen, closeHandle }: BasicModalProps): JSX.Element {

  const logoutUser = () => {
    logout()
  };

  return (
    <div>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={closeHandle}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div className="basic-modal--title">Quit</div>
            <div className="basic-modal--question">Are you sure, do you want to exit from the game?</div>
            <div className="basic-modal--buttons">
              <Button onClick={closeHandle} variant="contained">
                No
              </Button>
              <Button onClick={logoutUser}> Yes </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
