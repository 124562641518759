import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import RemotisRadioGroup from 'common/components/remotis-radio-group/RemotisRadioGroup';
import RemotisFormControlLabel from 'common/components/remotis-form-control-label/RemotisFormControlLabel';
import BpRadio from 'common/components/bp-radio/BpRadio';
import Ratio16x9 from 'assets/images/16.9.png';
import Ratio16x93 from 'assets/images/16.9x3.png';
import ApiLoader from 'client-server-provider/api-loader/ApiLoader';
import RemotisTextField from 'common/components/remotis-text-field/RemotisTextField';
import { ApiResponseProps } from 'client-server-provider/api-loader/ApiLoader.types';
import { useApiStateMutator } from 'client-server-provider/api-state-mutator/ApiStateMutator';
import { setConfig as setApiConfig } from 'client-server-provider/api-service/ApiService'

import { useEffect, useRef, useState } from 'react';
import { ApiGetRouteEnum, ApiPostRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';
import './Video.scss';

const resolutions = [
  {
    ratio: 16,
    image: Ratio16x9,
    name: '16:9',
  },
  {
    ratio: 21,
    name: '21:9',
    image: Ratio16x9,
  },
  {
    ratio: 32,
    name: '32:9',
    image: Ratio16x9,
  },
];

const CONFIG_KEY = 'video.aspect.ratio';

const Video = ({ apiResponse }: ApiResponseProps): JSX.Element => {

  const inputRef = useRef<HTMLInputElement | undefined>();
  const setApiState = useApiStateMutator(setApiConfig)
  const [config, setConfig] = useState<{id:number | null | string, value:number | string}>({id:null, value: ''});
  const [inputValue, setInputValue] = useState<string | number>('');

  const defaultSelected = resolutions.some(item => item.ratio.toFixed(3) == config.value);

  const updateVideoRatio = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    if(target && config.id) {
      setApiState({
        data:{id:config.id, value:target.value},
        params:{id:config.id},
        key: ApiPostRouteEnum.setConfig,
      })
    }
  };

  useEffect(() => {
    // if settings was found set id of the config on first render
    if (apiResponse && apiResponse.constructor == Array) {
      const aspectRatio = apiResponse.find((element) => element.key == CONFIG_KEY);
      if (aspectRatio) {
        setConfig({id:aspectRatio.id, value:aspectRatio.value})
      }
    }
  }, [apiResponse]);

  return (
    <div className="video">
      <div className="video__container">
        <h1 className='video--title'>Screen Ratio</h1>
        <RemotisRadioGroup defaultValue="16.9">
          <RemotisGrid container className="video__container__grid" >

            {resolutions.map((resolution, key) => {
              const ratio = resolution.ratio.toFixed(3)
              return (
                <RemotisGrid key={key}  item xs={4} className="video__screen-resolution">
                  <div className="video__content">
                    <img className="video__content--img" src={resolution.image} alt={`screen with resolution ${ratio}`}></img>

                    <div className="video__content--checkbox">
                      <RemotisFormControlLabel checked={config.value == ratio} onChange={updateVideoRatio} value={ratio} label={resolution.name} control={<BpRadio />} />
                    </div>

                  </div>
                </RemotisGrid>
              )
            })}
            {/* proof of concepts test version input(remove functionality after defaults found)  */}
            <RemotisGrid item xs={4} className="video__screen-resolution">


              <div className="video__content">
                <img className="video__content--img" src={Ratio16x93} alt={`screen with custom resolution`}></img>

                <div className="video__content--checkbox">
                  <RemotisFormControlLabel
                    checked={document.activeElement !== inputRef.current && !defaultSelected }
                    onChange={updateVideoRatio}
                    value={inputRef.current ? inputRef.current.value : undefined}
                    label={
                      <RemotisTextField
                        id="standard-basic"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        type="number"
                        value={document.activeElement !== inputRef.current && !defaultSelected && !inputValue ? config.value : inputValue}
                        onChange={(newValue) => setInputValue(newValue.target.value)}
                        inputRef={inputRef}
                        label="Custom Ratio:9"
                        variant="standard" />
                    }
                    control={<BpRadio />}
                  />
                </div>

              </div>
            </RemotisGrid>

          </RemotisGrid>
        </RemotisRadioGroup>
      </div>

    </div>
  );
};

// wrapper(high order component) that provide api response based on method provided from api service
export default ApiLoader({
  Component: Video,
  params: {key: CONFIG_KEY},
  queryKey: ApiGetRouteEnum.getConfig,
});
