import CardBox from 'common/components/card-box/CardBox';
import { SettingCardProps } from './SettingCard.types'
import './SettingCard.scss';

const SettingCard = ({ children, title }: SettingCardProps): JSX.Element => {
  return (
    <CardBox>
      <div className="setting-card">
        <h2>{title}</h2>
        <div className="setting-card__icon">
          {children}
        </div>
      </div>
    </CardBox>
  );
};

export default SettingCard;
