import './ReserveDetails.scss';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { ReserveDetailsProps } from './ReserveDetails.types';
import Moment from 'moment';

const ReserveDetails = ({ apiResponse }: ReserveDetailsProps): JSX.Element => {
  const responses = apiResponse && apiResponse.constructor == Array ? apiResponse: [];
  return (
    <div>
      {responses.map((response, key) => {
        return (
          <div key={key} className="reserve-details">
            <div className="reserve-details__select-date">
              <div className="reserve-details__date">
                <span className="reserve-details__month">
                  {Moment(response?.start).format('MMM')}
                </span>
                <br></br>
                <span className="reserve-details__number">
                  {Moment(response?.start).format('DD')}
                </span>
                <br></br>
                <span className="reserve-details__name">
                  {Moment(response?.start).format('ddd')}
                </span>
              </div>
              <div className="reserve-details__interval">
                {Moment(response?.start).format('HH:mm')} -
                {Moment(response?.end).format('HH:mm')}
              </div>
            </div>
            <RemotisGrid container className="reserve-details__race">
              <RemotisGrid item xs={4}>
                <p>
                  Event: <span>Free Practice</span>
                </p>
              </RemotisGrid>
              <RemotisGrid item xs={4}>
                <p>
                  Circuit layout: <span>Full Forward</span>
                </p>
              </RemotisGrid>
              <RemotisGrid item xs={4}>
                <p>
                  Rivals: <span>{response?.seats_left}</span>
                </p>
              </RemotisGrid>
            </RemotisGrid>
          </div>
        );
      })}
    </div>
  );
};

export default ReserveDetails;
