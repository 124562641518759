export enum ConfigNamesEnum {
    speed = 'gamepad.control.speed',
    brake = 'gamepad.control.brake',
    steering = 'gamepad.control.steering',
    turbo = 'gamepad.control.turbo',
    headLights = 'gamepad.control.headLights',
}

export enum ConfigControlEnum {
    interval = 'gamepad.control.send.interval',
    throttleReversed = 'gamepad.control.throttleReversed',
}