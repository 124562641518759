import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { Fragment } from 'react';
import RCoin from 'shop/components/r-coin/RCoin';

const RCoinLayout = () => {
  return (
    <Fragment>
      <RemotisGrid item xs={3}>
        <RCoin nrRcoin={25} />
      </RemotisGrid>

      <RemotisGrid item xs={3}>
        <RCoin nrRcoin={75} />
      </RemotisGrid>

      <RemotisGrid item xs={3}>
        <RCoin nrRcoin={25} />
      </RemotisGrid>

      <RemotisGrid item xs={3}>
        <RCoin nrRcoin={25} />
      </RemotisGrid>

      <RemotisGrid item xs={6}>
        <RCoin nrRcoin={25} />
      </RemotisGrid>

      <RemotisGrid item xs={6}>
        <RCoin nrRcoin={25} />
      </RemotisGrid>
    </Fragment>
  );
};

export default RCoinLayout;
