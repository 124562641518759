import ReplayVideo from 'common/components/replay-video/ReplayVideo';
import CardBox from 'common/components/card-box/CardBox';
import './Replays.scss';

const Replays = (): JSX.Element => {
  return (
    <CardBox>
      <div className="replay">
        <h2>REPLAYS</h2>

        <div className="replay__videos">
          <ReplayVideo url="test" date='2021-01-01' startTime="18:00" endTime="18:30" />
          <ReplayVideo url="test" date='2021-01-01' startTime="18:00" endTime="18:30" />
          <ReplayVideo url="test" date='2021-01-01' startTime="18:00" endTime="18:30" />
          <ReplayVideo url="test" date='2021-01-01' startTime="18:00" endTime="18:30" />
          <ReplayVideo url="test" date='2021-01-01' startTime="18:00" endTime="18:30" />
        </div>

      </div>
    </CardBox>
  );
};

export default Replays;
