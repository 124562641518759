import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { Fragment } from 'react';
import CarOwner from 'shop/components/car-owner/CarOwner';

const SpecCarLayout = () => {
  return (
    <Fragment>
      <RemotisGrid item xs={12}>
        <CarOwner />
      </RemotisGrid>
    </Fragment>
  );
};

export default SpecCarLayout;
