import LinearProgress from '@mui/material/LinearProgress';
import { addNewToast, deleteToast } from 'common/libraries';
import { Hourglass } from 'assets/icons/Icons';

import './LoadingToast.scss';

const toastId = 'loading-toast';
const LoadingToast = (): JSX.Element => {
  return (
    <div className='loading-toast'>
      <div className='loading-toast--icon'>
        <Hourglass/>
        <p>Loading...</p>
      </div>
      <div>
        <LinearProgress />
      </div>
    </div>
  );
};


const callLoadingToast = () => {
  addNewToast(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    {
      toastId: toastId,
      type: 'status',
      duration: 7000,
      component:  LoadingToast,
    }
  );
}

export const deleteLoadingToast = () => {
  deleteToast(toastId)
}

export default callLoadingToast;