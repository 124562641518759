import CardBox from 'common/components/card-box/CardBox';
import './Rcoin.scss';
import rcoin from 'assets/images/rcoin.png';
import { Button } from '@mui/material';
import { RCoinProps } from './RCoin.types';

const RCoin = ({ nrRcoin }: RCoinProps): JSX.Element => {
  return (
    <CardBox>
      <div className="rcoin">
        <img src={rcoin} alt="" />
        <p className="rcoin__nr-rcoin"> {nrRcoin}</p>
        <p className="rcoin__title body-text"> R-Coin </p>
        <Button> Price </Button>
      </div>
    </CardBox>
  );
};

export default RCoin;
