import logo from 'assets/images/Logo.png';
import profile from 'assets/images/Profile.png';
import flag from 'assets/images/Flag.png';
import { Container } from '@mui/material';
import license from 'assets/images/logo-info.png';
import { NavLink } from 'react-router-dom';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { useGetAccountInfo, FormatAmount, useGetNetworkConfig } from 'common/libraries';
import { sideBarIndexRoutes } from 'routes';


import './Menu.scss';
import React from 'react';

const Menu = () => {
  const  { account } = useGetAccountInfo();
  const { network } = useGetNetworkConfig();

  return (
    <div className="menu">
      <img className="menu__logo" src={logo} alt="" />

      <div className="menu__container">
        <img className="menu__container--profile" src={profile} alt="user profile" />

        <div className="menu__container__info">

          <div className="menu__container__info--user-details">
            <div className='menu__container__info--user-details--user'>
              <img className="menu__container__info--flag" src={flag} alt="" />
              <span >{account.address}</span>
            </div>
            <span className="tooltip-text">{account.address}</span>
          </div>

          <Container className="menu__container__profile">

            <RemotisGrid container>
              <RemotisGrid item xs={4} className="menu__container__profile--item-description">
                <div className="value">2</div>
                <div className="name">Slot Res.</div>
              </RemotisGrid>

              <RemotisGrid item xs={4} className="mid menu__container__profile--item-description">
                <div className="value">
                  <img src={license} alt="" />
                </div>
                <div className="name">License</div>
              </RemotisGrid>

              <RemotisGrid item xs={4} className="menu__container__profile--item-description menu__container__profile--coin">
                <FormatAmount
                  className='menu__container__profile--item-description--text'
                  value={account.balance}
                  egldLabel={network.egldLabel}
                  data-testid='balance'
                  digits={2}
                />
              </RemotisGrid>
            </RemotisGrid>

            <div className="menu__container--navLinks">
              { sideBarIndexRoutes.map((route) => {
                return (
                  <NavLink key={route.path} to={route.path}   caseSensitive className={({ isActive }) => (isActive ? 'activeBtn' : '')}>
                    <hr/>
                    <div className="menu__container--navLinks--item">
                      <p className="navLink_content">
                        {React.createElement(route.icon)}
                        {route.title}
                      </p>
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Menu;
