import LinearProgress from '@mui/material/LinearProgress';
import { addNewToast, deleteToast } from 'common/libraries';
import { Flag } from 'assets/icons/Icons';
import { SuccessToastProps } from './SuccessToast.types'


import './SuccessToast.scss';

const toastId = 'success-toast';
const SuccessToast = ({message}: SuccessToastProps): JSX.Element => {
  return (
    <div className='success-toast'>
      <div className='success-toast--icon'>
        <Flag/>
        <p>{message}</p>
      </div>
      <div>
        <LinearProgress variant="determinate" value={100}/>
      </div>
    </div>
  );
};


const successToast = (error:string) => {
  return <SuccessToast message={error}/>
}

const callSuccessToast = (message:string) => {
  addNewToast(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    {
      toastId: toastId,
      type: 'status',
      duration: 7000,
      component:  () => successToast(message),
    }
  );
}

export const deleteSuccessToast = () => {
  deleteToast(toastId)
}

export default callSuccessToast;