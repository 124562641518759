import { paths, components } from "remotisApi.types";
import axios, { AxiosHeaders, AxiosRequestHeaders } from 'axios';

const domain = import.meta.env.VITE_API_BASE_URL;

const baseFetch = (
  route:string,
  method:string,
  params:unknown = null,
  data:unknown = null,
  headers:AxiosRequestHeaders = new AxiosHeaders()
) => {
  return axios({url: `${domain}${route}`, data:data, method: method, params:params, headers:headers })
    .then(response => {return response.data })
}

export const getConfig = (
  queryParams?:paths['/v1/api/config']['parameters']['query'],
  headers:AxiosRequestHeaders = new AxiosHeaders()
) => {
  const path = '/configs'

  return baseFetch(path, 'GET', queryParams, null, headers)
}

export const setConfig = (
  data:components['schemas']['Config'] | unknown,
  queryParams?:Record<string | number, string | number>,
  headers:AxiosRequestHeaders = new AxiosHeaders()
) => {
  const path = '/config'

  return baseFetch(path, 'PUT', queryParams, data, headers)
}

export const setBatchConfig = (
  data:components['schemas']['Config'] | unknown,
  queryParams?:Record<string | number, string | number>,
  headers:AxiosRequestHeaders = new AxiosHeaders()
) => {
  const path = '/batch-upsert'

  return baseFetch(path, 'PUT', queryParams, data, headers)
}

export const getRaceCalendar = (
  queryParams?: paths['/v1/api/race/calendar']['parameters']['query'] | paths['/v1/api/config']['parameters']['query'],
  headers:AxiosRequestHeaders = new AxiosHeaders()
) => {
  const path = '/race/calendar'

  return baseFetch(path, 'GET', queryParams, null, headers)
}
