import { FormControlLabel } from '@mui/material';
import { RemotisFormControlLabelProps } from './RemotisFormControlLabel.types';
import './RemotisFormControlLAabel.scss'

const RemotisFormControlLabel = (props : RemotisFormControlLabelProps): JSX.Element => {

  return (
    <FormControlLabel {...props} />
  );

}

export default RemotisFormControlLabel;
