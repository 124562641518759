import React, { useState } from "react";
import Slide from '@mui/material/Slide';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './SlidingDropDown.scss'

export interface currentItemsProps {
  index: number;
  direction: 'right' | 'left' | 'up' | 'down';
}

export interface SlidingDropDownProps {
  callback?: (id:string | number, value:string|number) => void
  title: string,
  id: string | number,
  icon?: string | React.FunctionComponent,
  savedValue?: string | number
  data: {title:string, value: string | number}[];
}

function SlidingDropDown({id, title, icon, data, savedValue, callback} : SlidingDropDownProps) : JSX.Element {

  const setSelection = (currentValue: number) => {
    console.log(savedValue)
    // if (currentValue !== null && currentValue) {
    //   if (callback) {
    //     callback(id, 2);
    //   }
    // }
  };

  const [currentItem, setCurrent] = useState<currentItemsProps>({
    direction: 'right',
    index: 0,
  });

  const handleChange = (direction: 'left' | 'right' | 'up' | 'down') => {

    let currentSet = direction === 'left' ? currentItem.index += 1 :  currentItem.index -= 1;

    const dataLength = data.length;
    if (currentSet < 0) {
      currentSet = dataLength - 1;
    }

    if (currentSet === dataLength) {
      currentSet = 0;
    }

    setSelection(currentSet);
    setCurrent({index:currentSet, direction:direction });
  };

  return (
    <div className="slide-selectors">

      <div className="slide-selectors--icon">
        {icon &&
          React.createElement(icon)
        }
      </div>
      <p className="slide-selectors--title">{title}</p>
      <div className="slide-selectors__container">
        <ArrowBackIosIcon
          className="slide-selectors__navigators"
          onClick={() => handleChange('right')}
        />

        <div className="slide-selectors__content">
          {data.map((element, index) => {
            return (
              <div className='slide-selectors__elements' key={element.value}>
                <Slide direction={currentItem.direction} in={index === currentItem.index}>
                  <p>{element.title}</p>
                </Slide>
              </div>
            );
          })}
        </div>

        <ArrowForwardIosIcon
          className="slide-selectors__navigators"
          onClick={() => handleChange('left')}
        />
      </div>
    </div>
  );
}

export default SlidingDropDown;