import Loader  from 'common/components/loader/Loader';
import Controller from 'assets/images/controller.png';
import WheelImage from 'assets/images/wheelImage.png';
import { GamepadStatusProps } from './GamepadStatus.types';

import './GamepadStatus.scss';

const GamepadStatus = (props:GamepadStatusProps): JSX.Element => {
  const {gamepads} = props;

  if(gamepads.length !== 0) {
    return <img src={Controller} alt="selected input controller" />;
  }

  return <>
    <p className='gamepad-status--title '>No device connected</p>
    <div className='gamepad-status__loader-container'>
      <Loader size={150}/>
    </div>
    <p className='gamepad-status--help-text'>
        Please connect a controller or a wheel and prees any key to connect
    </p>
  </>;
}

export default GamepadStatus;