import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Profile from '../profile/Profile';
import Menu from '../menu/Menu';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './MenuBurger.scss';

type Anchor = 'left';

export default function ResponsiveDrawer() {

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <div className="menu-burger">

      <div className="menu-burger__profile">
        <Profile>
          <Menu />
        </Profile>
      </div>

      <IconButton  className="menu-burger__button" onClick={toggleDrawer('left', true)}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>

        <Profile>
          <Menu />
        </Profile>

      </Drawer>
    </div>
  );
}
