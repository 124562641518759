import LicenseAmateur from 'assets/images/license-amateur.svg?react';
import CardBox from 'common/components/card-box/CardBox';
import React from 'react';
import { LicenseSummary } from './License.types';
import './License.scss';

const License = ({
  licenseLevel,
  monthlyRcoins,
  maxPower,
  fps,
  boostEachLap,
  carLiveries
}: LicenseSummary): JSX.Element => {

  return (
    <CardBox>
      <div className="license">
        <p className="license__title">{licenseLevel}</p>
        <div className="license__content">

          <LicenseAmateur className="license__content--image"/>

          <span className="license__content--option">
            License level: <span className='body-text'>{licenseLevel}</span>
          </span>

          <span className="license__content--option">
            Monthly R-Coins: <span className="body-text r-coin">{monthlyRcoins}</span>
          </span>

          <span className="license__content--option">
            Max Power:
            <span>
              {maxPower} <span className="body-text rpm">RPM</span>
            </span>
          </span>

          <span className="license__content--option">
            Frame per second: <span className='body-text'>{fps}</span>
          </span>

          <span className="license__content--option">
            Boost each lap: <span className='body-text'>{boostEachLap}</span>
          </span>

          <span className="license__content--option">
            Car liveries: <span className='body-text'>{carLiveries}</span>
          </span>

        </div>
      </div>
    </CardBox>
  );
};

export default React.memo(License);
