
function avoidEval(string:string, gamepad: string | Gamepad | null):number {
  // get from gamepad where config value(index) match
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return string?.split('][').reduce(function(o: { [x: string]: unknown; }, k:string) {
    const formattedValue = k.replace(/[\\[\]]/gi, '');
    return o && o[formattedValue];
  }, gamepad);

}

export default avoidEval;
