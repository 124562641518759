import LinearProgress from '@mui/material/LinearProgress';
import { addNewToast, deleteToast } from 'common/libraries';
import { Hourglass } from 'assets/icons/Icons';
import { ErrorToastProps } from './ErrorToast.types'

import './ErrorToast.scss';

const toastId = 'error-toast';
const ErrorToast = ({message}: ErrorToastProps): JSX.Element => {
  return (
    <div className='error-toast'>
      <div className='error-toast--icon'>
        <Hourglass/>
        <p>{message}</p>
      </div>
      <div>
        <LinearProgress variant="determinate" value={100}/>
      </div>
    </div>
  );
};

const errorToast = (error:string) => {
  return <ErrorToast message={error}/>
}


const callErrorToast = (error:string) => {
  addNewToast(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    {
      toastId: toastId,
      type: 'status',
      duration: 7000,
      component:  () => errorToast(error),
    }
  );
}

export const deleteErrorToast = () => {
  deleteToast(toastId)
}

export default callErrorToast;