import { useEffect, useState } from 'react';
import './CalendarTime.scss';

const CalendarTime = () => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Sunday = 0, Monday = 1 ...
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [dayNr, setDayNr] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const today = new Date();
      setMonth('' + monthNames[today.getMonth()]),
      setDay('' + dayNames[today.getDay()]),
      setDayNr('' + today.getDate()),
      setTime(
        '' + today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
      )
    });

    return () => clearInterval(interval);
  },);

  return (
    <div className="calendar">
      <div className="calendar__date">
        <div>{month}</div>
        <div className="calendar__date--day">{dayNr}</div>
        <div>{day}</div>
      </div>
      <div className="calendar__time">
        <div className="calendar__time--clock">{time}</div>
        <div className="calendar__time--next"> Next race</div>
        <div className="calendar__time--timer"> 00:58:00 </div>
      </div>
    </div>
  );
};

export default CalendarTime;
