import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';

const ComingSoon = () => {
  return (
    <RemotisGrid item>
      {' '}
      <h1>Coming soon ...</h1>
    </RemotisGrid>
  );
};

export default ComingSoon;