import Radio, { RadioProps } from '@mui/material/Radio';
import './BpRadio.scss'

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      {...props}
    />
  );
}

export default BpRadio;
