import CardBox from 'common/components/card-box/CardBox';
import ElementSubMenu from 'common/components/element-sub-menu/ElementSubMenu';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import SubMenu from 'common/components/sub-menu/SubMenu';
import { SecondaryMenuProps } from './SecondaryMenu.types'
import { Outlet } from 'react-router-dom';

const SecondaryMenu = ({ routes,  basePath}: SecondaryMenuProps): JSX.Element => {

  return (
    <RemotisGrid container className="spacing">

      {/* if there are more than one link show navigation */}
      <SubMenu goBackPath={basePath}>
        {routes.length >= 1 &&
          routes.map((route, key: number) => {
            return <ElementSubMenu path={`${basePath}/${route.path}`} menuTab={route.title} key={key} />;
          })
        }
      </SubMenu>

      <RemotisGrid container>

        <RemotisGrid item>
          <CardBox>
            <Outlet />
          </CardBox>
        </RemotisGrid>

      </RemotisGrid>

    </RemotisGrid>
  );
};

export default SecondaryMenu;
