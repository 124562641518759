import carB from 'assets/images/car-b.png';
import CardBox from 'common/components/card-box/CardBox';
import React from 'react';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { CarSetupProps } from './CarSetup.types';
import { Sound, PaintGun } from 'assets/icons/Icons';
import './CarSetup.scss';

const CarSetup = ({ painting, material, sound }: CarSetupProps): JSX.Element => {

  const aestheticSetup = [
    {
      value: painting,
      icon: PaintGun
    },
    {
      value: material,
      icon: PaintGun
    },
    {
      value: sound,
      icon: Sound
    },
  ];

  return (
    <CardBox>
      <div className="car-setup">
        <h2>CAR SETUP</h2>
        <div className="car-setup__description">
          <RemotisGrid container spacing={2}>

            {aestheticSetup.map((setup, key) => {
              return (
                <RemotisGrid key={key} item xs={4}>
                  <setup.icon className='body-text car-setup__description--icon' fontSize="large"  inheritViewBox />
                  <span className='body-text car-setup__description--value'>{setup.value}</span>
                </RemotisGrid>
              )
            })}

          </RemotisGrid>
          <img src={carB} className="car-setup__description--car-b" alt="remotis car basic type" />
        </div>
      </div>
    </CardBox>
  );
};

export default React.memo(CarSetup);
