import SettingsLayout from 'settings/layout/settings/SettingsLayout';
import MyProfile from 'my-profile/layout/my-profile/MyProfileLayout';
import CarAestheticLayout from 'my-profile/layout/car-aesthetic/CarAestheticLayout';

import RCoinLayout from 'shop/layout/r-coin/RCoinLayout';
import SpecCarLayout from 'shop/layout/spec-car/SpecCarLayout';
import TestNetwork from 'settings/components/test-network/TestNetwork';
import Regulations from 'settings/components/regulations/Regulations';
import Video from 'settings/components/video/Video';
import SecondaryMenu from 'common/layout/secondary-menu/SecondaryMenu';
import CarSetupLayout from 'common/layout/car-setup/CarSetupLayout';
import GameController from 'settings/components/game-controller/GameController';
import AccountManagement from 'settings/components/account-management/AccountManagement';
import ShopLayout from 'shop/layout/shop-layout/ShopLayout';
import LayoutCardLicenseCard from 'shop/layout/license-card/LicenseCardLayout';
import Faq from 'settings/components/faq/Faq';
import SdpCodecs from 'settings/components/sdp-codecs/SdpCodecs';
import PaymentAndBilling from 'settings/components/payment-and-billing/PaymentAndBilling';
import SuccessPage from 'common/layout/success-page/SuccessPage';

import {
  Dispatcher,
  Headphones,
  PaperWorks,
  Profile,
  ScreenResolution,
  SteeringWheel,
  WiFi,
  SettingsFill,
  HelmetFillInclined,
  Shop,
  Flag,
  FrontFormula1,
  CircuitMap,
  Ranking,
  HeadphonesMicrophone,
  VideoPlay
} from 'assets/icons/Icons';
import CalendarSlotsLayout from 'racing/layout/calendar-slots/CalendarSlotsLayout';
import ReserveSlot from 'racing/layout/reserve-slot/ReserveSlotLayout';
import CarsLayout from 'cars/layout/CarsLayout';
import RemotisRM from 'cars/components/RemotisRM';
import CircuitsLayout from 'circuits/layout/CircuitsLayout';
import Z1Ring from 'circuits/components/z1ring/Z1Ring';
import TrackIntroduction from 'circuits/components/track-introduction/TrackIntroduction';
import SupportLayout from 'support/components/Support';
import ComingSoon from 'circuits/components/coming-soon/ComingSoon';
import RankingLayout from 'ranking/layout/RankingLayout';
import LiveRace from 'live-race/layout/live-race/LiveRace';

interface SecondaryMenuProps {
  title: string;
  basePath: string;
  paths: {
    title: string;
    path: string;
    component: () => JSX.Element;
    index: boolean;
    icon: string | React.FunctionComponent;
  }[];
}

interface indexRoutesProps {
  title: string;
  path: string;
  component: () => JSX.Element;
  icon: string | React.FunctionComponent;
}

export const startingRoute = '/my-profile';

// basePath is a path that all paths will have and baseComponent is a wrapper component for paths
// if a path has index true, the route will appear in the primary navigation of the layout
const settings = {
  basePath: '',
  baseComponent: null,
  title: 'settings',
  paths: [
    {
      index: true,
      path: 'settings',
      component: SettingsLayout,
      title: 'shop',
      icon: SettingsFill,
    },
  ],
};

const audio = {
  basePath: '',
  baseComponent: null,
  title: 'Audio',
  paths: [
    {
      index: true,
      component: SettingsLayout,
      path: 'settings',
      title: 'settings',
      icon: Headphones,
    },
  ],
};

const network = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'network',
  paths: [
    {
      index: true,
      path: 'network/test-network-connection',
      component: TestNetwork,
      title: 'Test Network Connection',
      icon: WiFi,
    },
    {
      index: false,
      component: TestNetwork,
      path: 'network/test-network',
      title: 'Test Network',
      icon: '',
    },
  ],
};

const gameDevices = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'game devices',
  paths: [
    {
      index: true,
      component: GameController,
      path: 'game-devices/controller',
      title: 'Devices',
      icon: SteeringWheel,
    },
  ],
};

const account = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'Account',
  paths: [
    {
      index: true,
      component: AccountManagement,
      path: 'account/account-management',
      title: 'Account Management',
      icon: Profile,
    },
    {
      icon: '',
      component: AccountManagement,
      index: false,
      path: 'account/subscription',
      title: 'Subscription',
    },
    {
      index: false,
      component: PaymentAndBilling,
      path: 'account/paymant-and-billing',
      title: 'Payment And Billing',
      icon: '',
    },
    {
      index: false,
      component: AccountManagement,
      path: 'account/linked-social-accounts',
      title: 'Linked Social Accounts',
      icon: '',
    },
  ],
};

const video = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'video',
  paths: [
    {
      index: true,
      path: 'video/screen-ratio',
      title: 'screen-ratio',
      component: Video,
      icon: ScreenResolution,
    },
    {
      icon: '',
      index: false,
      path: 'video/video',
      title: 'video',
      component: CarSetupLayout,
    },
    {
      index: true,
      component: SdpCodecs,
      path: 'SDP',
      title: 'SDP',
      icon: VideoPlay,
    },
  ],
};

const regulations = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'Regulation',
  paths: [
    {
      index: true,
      component: Regulations,
      path: 'regulations/terms-and-conditions',
      title: 'Terms and Conditions',
      icon: PaperWorks,
    },
    {
      index: false,
      component: Regulations,
      path: 'regulations/track-recolamentation',
      title: 'Subscription',
      icon: '',
    },
  ],
};

const faq = {
  basePath: '/settings',
  baseComponent: SecondaryMenu,
  title: 'faq',
  paths: [
    {
      index: true,
      component: Faq,
      path: 'faq',
      title: 'Faq',
      icon: Dispatcher,
    },
  ],
};

const shop = {
  basePath: '/shop',
  baseComponent: ShopLayout,
  title: 'shop',
  paths: [
    {
      index: true,
      component: LayoutCardLicenseCard,
      path: 'license',
      title: 'License',
      icon: Shop,
    },
    {
      index: false,
      component: RCoinLayout,
      path: 'r-coin',
      title: 'R-coin',
      icon: Shop,
    },
    {
      index: false,
      component: SpecCarLayout,
      path: 'car-owner',
      title: 'Car Owner',
      icon: '',
    },
  ],
};

const carSetup = {
  basePath: '/my-profile',
  baseComponent: SecondaryMenu,
  title: 'my profile',
  paths: [
    {
      index: true,
      component: CarSetupLayout,
      path: 'car-setup/mechanics',
      title: 'Mechanics',
      icon: '',
    },
    {
      index: false,
      component: CarAestheticLayout,
      path: 'car-setup/aesthetics',
      title: 'Aesthetics',
      icon: '',
    },
  ],
};

const myProfile = {
  basePath: '',
  baseComponent: null,
  title: 'my profile',
  paths: [
    {
      index: true,
      component: MyProfile,
      path: 'my-profile',
      title: 'My Profile',
      icon: HelmetFillInclined,
    },
  ],
};

const racingDetails = {
  basePath: '/racing',
  baseComponent: null,
  title: 'racing',
  paths: [
    {
      index: true,
      component: CalendarSlotsLayout,
      path: 'calendar',
      title: 'Calendar',
      icon: Flag,
    },
    {
      index: false,
      component: ReserveSlot,
      path: 'reserve',
      title: 'Reserve',
      icon: Flag,
    },
  ],
};

const racing = {
  basePath: '',
  baseComponent: null,
  title: 'racing',
  paths: [
    {
      index: true,
      component: CalendarSlotsLayout,
      path: 'racing',
      title: 'racing',
      icon: Flag,
    },
  ],
};

const cars = {
  basePath: '/cars',
  baseComponent: CarsLayout,
  title: 'cars',
  paths: [
    {
      index: true,
      component: RemotisRM,
      path: 'rm8',
      title: 'RM8',
      icon: FrontFormula1,
    },
    {
      index: false,
      component: ComingSoon,
      path: 'coming-soon',
      title: 'Coming soon',
      icon: FrontFormula1,
    },
  ],
};

const circuits = {
  basePath: '/circuits',
  baseComponent: CircuitsLayout,
  title: 'circuits',
  paths: [
    {
      index: true,
      component: Z1Ring,
      path: 'z1ring',
      title: `Z1'S Ring`,
      icon: CircuitMap,
    },
    {
      index: false,
      component: ComingSoon,
      path: 'coming-soon',
      title: 'Coming soon',
      icon: CircuitMap,
    },
  ],
};

const trackIntroduction = {
  basePath: '/circuits',
  baseComponent: '',
  title: '',
  paths: [
    {
      index: true,
      component: TrackIntroduction,
      path: 'z1ring/trackintroduction',
      title: 'trackIntroduction',
      icon: HeadphonesMicrophone,
    },
  ],
};

const support = {
  basePath: '',
  baseComponent: '',
  title: 'support',
  paths: [
    {
      index: true,
      component: SupportLayout,
      path: 'support',
      title: 'support',
      icon: HeadphonesMicrophone,
    },
  ],
};

const ranking = {
  basePath: '',
  baseComponent: '',
  title: 'ranking',
  paths: [
    {
      index: true,
      component: RankingLayout ,
      path: 'ranking',
      title: 'ranking',
      icon: Ranking,
    },
  ],
};

const garage = {
  basePath: '',
  baseComponent: '',
  title: 'garage',
  paths: [
    {
      index: true,
      component: LiveRace ,
      path: 'garage',
      title: 'garage',
      icon: Ranking,
    },
  ],
};

const successPage = {
  basePath: '',
  baseComponent: '',
  title: 'success',
  paths: [
    {
      index: true,
      component: SuccessPage ,
      path: 'success-page',
      title: 'success-page',
      icon: Ranking,
    },
  ],
};


const sideNavRoutes = [myProfile, racing, shop, cars, ranking, circuits, settings, support];

const settingsRoutes = [account, network, gameDevices, video, audio, regulations, faq];

// all routes of the application
export const routes = [garage, carSetup, racingDetails, ...sideNavRoutes, ...settingsRoutes, trackIntroduction, successPage];

// gets the main routes(first navigation bar of the layout), where route index is a boolean with true if main
const getIndexRoutes = (routes: SecondaryMenuProps[]) => {
  const indexRoutes: indexRoutesProps[] = [];
  routes.forEach((settingsRoute: SecondaryMenuProps) => {
    const indexRoute = settingsRoute.paths.find((paths) => paths.index === true);

    if (indexRoute) {
      indexRoutes.push({
        path: `${settingsRoute.basePath}/${indexRoute.path}`,
        title: settingsRoute.title,
        component: indexRoute.component,
        icon: indexRoute.icon,
      });
    }
  });

  return indexRoutes;
};

export const settingsIndexRoutes = getIndexRoutes(settingsRoutes);
export const sideBarIndexRoutes = getIndexRoutes(sideNavRoutes);
