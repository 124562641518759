import { ConfigStateMessagesProps } from './ConfigStateMessages.types';
import { gamepadAvailableActions } from '../GamepadAvailableActions';

import './ConfigStateMessages.scss';

const ConfigStateMessages = (props:ConfigStateMessagesProps): JSX.Element => {
  const {
    newButtonSelected,
    apiResponse,
  } = props;


  if (newButtonSelected) {
    return <span className='config-state-messages warning'>!! The Button you pressed is unassigned !!</span>;
  }

  if (apiResponse && apiResponse.constructor == Array) {
    const allKeyAssigned = Object.keys(gamepadAvailableActions).every(key =>
      apiResponse.some(item => item.key === key && item.value)
    );

    if (!allKeyAssigned) {
      return <span className='config-state-messages warning'>To play You need to Assign all the actions</span>;
    }
  }

  return <>
    <span className='config-state-messages warning'>   </span>
    <span className='config-state-messages success'>All action have been assigned correctly</span>
  </>
}

export default ConfigStateMessages;