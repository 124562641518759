import { MuteAudio, Sound } from 'assets/icons/Icons';
import { useEffect, useState } from 'react';
import { AudioToggleProps } from './AudioToggle.types'
import './AudioToggle.scss'

const AudioToggle = ({ videoId }: AudioToggleProps): JSX.Element => {
  const [audio, setAudio] = useState(false)

  const switchAudio = () => {
    const video = document.getElementById(videoId) as HTMLVideoElement;
    if(video) {
      video.muted = !video.muted;
      setAudio(!video.muted)
    }
  }

  useEffect(() => {
    const video = document.getElementById(videoId) as HTMLVideoElement;
    if(video) {
      setAudio(video.muted)
    }
  }, [videoId]);


  return (
    <div className="audio-toggle">
      <div className="audio-toggle__actions">
        { audio && <Sound onClick={switchAudio}/>}
        { !audio && <MuteAudio onClick={switchAudio}/>}
      </div>
    </div>
  );
};

export default AudioToggle;