import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RemotisCircularProgressProps } from './RemotisCircularProgress.types';

import './RemotisCircularProgress.scss';

const RemotisCircularProgress = ({name, value, maxValue}: RemotisCircularProgressProps): JSX.Element => {

  return (
    <div className='remotis-circular-progress '>
      <CircularProgress className='remotis-circular-progress__progress' variant="determinate" size="4vh" value={value}/>
      <CircularProgress className='remotis-circular-progress__background' variant="determinate" size="4vh" value={maxValue}/>
      <Box className='remotis-circular-progress--text'>
        <Typography>{name}</Typography>
      </Box>
    </div>
  );
}

export default RemotisCircularProgress;