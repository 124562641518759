import RemotisAccordion from 'common/components/remotis-accordion/RemotisAccordion'

import './Faq.scss';

const questions = [
  {
    title: 'Can I cancel my License Subscription at any time?',
    detail: 'Single stationary yellow flag means overtaking prohibited due to danger near the track. Single waved yellow flag means overtaking prohibited due to danger on the track. Double waved yellow flag means overtaking prohibited due to blocked track. At both flags, a speed reduction is required as well.'
  },
  {
    title: 'Can I cancel my License Subscription at any time?',
    detail: 'Single stationary yellow flag means overtaking prohibited due to danger near the track. Single waved yellow flag means overtaking prohibited due to danger on the track. Double waved yellow flag means overtaking prohibited due to blocked track. At both flags, a speed reduction is required as well.'
  },
  {
    title: 'Can I cancel my License Subscription at any time?',
    detail: 'Single stationary yellow flag means overtaking prohibited due to danger near the track. Single waved yellow flag means overtaking prohibited due to danger on the track. Double waved yellow flag means overtaking prohibited due to blocked track. At both flags, a speed reduction is required as well.'
  },
  {
    title: 'Can I cancel my License Subscription at any time?',
    detail: 'Single stationary yellow flag means overtaking prohibited due to danger near the track. Single waved yellow flag means overtaking prohibited due to danger on the track. Double waved yellow flag means overtaking prohibited due to blocked track. At both flags, a speed reduction is required as well.'
  }
];

const Faq = () => {
  return (
    <div className="faq">
      <div className="faq__accordions">
        {questions.map(( question, key ) => {
          return (
            <RemotisAccordion key={key} id={key} title={question.title} detail={question.detail}/>
          )
        })}
      </div>
    </div>
  );
};

export default Faq;
