import './Regulations.scss';

const termsAndConditions = [
  {
    title: 'YOUR AGREEMENT',
    detail: 'Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may \
            be used for informational purposes only. Because the Terms and Conditions contain legal \
            obligations, please read them carefully.'
  },
  {
    title: 'PRIVACY',
    detail: 'Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.'
  },
  {
    title: 'LINKED SITE',
    detail: 'This Site may contain links to other independent third-party Web sites (“Linked Sites”). \
            These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites \
            are not under our control, and we are not responsible for and does not endorse the content \
            of such Linked Sites, including any information or materials contained on such Linked Sites. \
            You will need to make your own independent judgement regarding your interaction with these \
            Linked Sites.'
  },
  {
    title: 'FORWARD LOOKING STATEMENTS',
    detail: 'All materials reproduced on this site speak as of the original date of publication or \
            filing. The fact that a document is available on this site does not mean that the \
            information contained in such document has not been modified or superseded by events or by a \
            subsequent document or filing. We have no duty or policy to update any information or \
            statements contained on this site and, therefore, such information or statements should not \
            be relied upon as being current as of the date you access this site. \n\n \
            We have no duty or policy to update any information or statements contained on this site \
            and, therefore, such information or statements should not be relied upon as being current as \
            of the date you access this site.'
  },
  {
    title: 'FORWARD LOOKING STATEMENTS',
    detail: 'All materials reproduced on this site speak as of the original date of publication or \
          filing. The fact that a document is available on this site does not mean that the \
          information contained in such document has not been modified or superseded by events or by a \
          subsequent document or filing. We have no duty or policy to update any information or \
          statements contained on this site and, therefore, such information or statements should not \
          be relied upon as being current as of the date you access this site. \n\n \
          We have no duty or policy to update any information or statements contained on this site \
          and, therefore, such information or statements should not be relied upon as being current as \
          of the date you access this site.'
  }
];

const Regulations = () => {
  return (
    <div className="regulations" id="regulations">

      <div className="regulations__disclaimer">
        <h1 className='regulations__title'>General Site Usage</h1>
        <p>
          Last Revised: December 16, 2013
          <br></br> <br></br>
          Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may
          be used for informational purposes only. Because the Terms and Conditions contain legal
          obligations, please read them carefully.
        </p>
      </div>

      {termsAndConditions.map(( terms, key ) => {
        return (
          <div className="regulations__conditions" key={key}>
            <h1 className='regulations__title'>{`${key + 1}. ${terms.title}`}</h1>
            <p>
              {terms.detail}
            </p>
          </div>
        )
      })}

    </div>
  );
};

export default Regulations;
