import ButtonsLoadedValue from './buttons-loaded-value/ButtonsLoadedValue'
import { UserInteractionsMessagesProps } from './UserInteractionsMessages.types';

import './UserInteractionsMessages.scss';
import rangeValueConverter from 'helpers/RangeValueConvertor';
import PositionSlider from './position-slider/PositionSlider';
import avoidEval from 'helpers/AvoidEval';


const UserInteractionsMessages = (props:UserInteractionsMessagesProps): JSX.Element => {
  const {
    apiResponse,
    actionUpdated,
    id,
    currentUpdatingAction,
    gamepadCurrentState,
    gamepadCurrentConfig,
  } = props; // change this to false

  if(actionUpdated && currentUpdatingAction === id) {
    return <span className='user-interaction-message success'>Button assigned</span>;
  }

  if(
    currentUpdatingAction === id ||
    ((currentUpdatingAction === 'gamepad.control.brake' ||
     currentUpdatingAction === 'gamepad.control.speed') &&
     id === 'throttle')
  ) {
    return <span className='user-interaction-message info'>Press a button</span>;
  }
  if (apiResponse && apiResponse.constructor == Array) {
    if(id === 'throttle') {
      const apiSettingBrake = apiResponse.find(item => item.key === 'gamepad.control.brake');
      const apiSettingSpeed = apiResponse.find(item => item.key === 'gamepad.control.speed');
      const reversedThrottle = apiResponse.find(item => item.key === 'gamepad.control.throttleReversed');

      const foundedSpeedGamepadByHash = gamepadCurrentState.find((gamepad, gamepadIndex) => String(gamepadIndex) === apiSettingSpeed?.hash);
      const foundedBrakeGamepadByHash = gamepadCurrentState.find((gamepad, gamepadIndex) => String(gamepadIndex) === apiSettingBrake?.hash);

      const brake = gamepadCurrentConfig['gamepad.control.brake'];
      const speed = gamepadCurrentConfig['gamepad.control.speed'];

      const gamepadBrake = brake?.value ? avoidEval(brake.value, foundedBrakeGamepadByHash ?? null) : 0;
      const gamepadSpeed = speed?.value ? avoidEval(speed.value, foundedSpeedGamepadByHash ?? null) : 0;


      const normBrake = brake?.value.includes("axes") ? rangeValueConverter(-1, 1, 1, 0, gamepadBrake) : gamepadBrake
      const normSpeed = speed?.value.includes("axes") ? rangeValueConverter(-1, 1, 1, 0, gamepadSpeed) : gamepadSpeed

      let throttle = normSpeed - normBrake;
      if(reversedThrottle && reversedThrottle.value === 'true') {
        throttle *= -1;
      }

      const value = rangeValueConverter(1, -1, 100, 0, Number(throttle));

      return <span className='user-interaction-message'><PositionSlider value={value}/></span>
    }

    const apiSetting = apiResponse.find(item => item.key === id);
    const foundedGamepadByHash = gamepadCurrentState.find((gamepad, gamepadIndex) => String(gamepadIndex) === apiSetting?.hash);

    const result = avoidEval(apiSetting?.value ?? '', foundedGamepadByHash ?? null)
    const value:number = apiSetting?.value.includes("axes") ? rangeValueConverter(1, -1, 100, 0, Number(result)) : rangeValueConverter(1, 0, 100, 0, Number(result));

    if(apiSetting?.value.includes("axes")) {
      return <span className='user-interaction-message'><PositionSlider value={value}/></span>
    }

    return <span className='user-interaction-message'><ButtonsLoadedValue value={value}/></span>;
  }

  return <span className='user-interaction-message warning'>No button assigned</span>;
}

export default UserInteractionsMessages;