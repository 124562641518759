import { RadioGroup } from '@mui/material';
import { RemotisRadioGroupProps } from './RemotisRadioGroup.types'

const RemotisGrid = (props : RemotisRadioGroupProps): JSX.Element => {
  const {children, ...gridProps} = props;

  return (
    <RadioGroup {...gridProps}>
      {children}
    </RadioGroup>
  );
}

export default RemotisGrid;
