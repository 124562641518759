import { Button } from '@mui/material';
import { RemotisButtonProps } from './RemotisButton.types'
import './RemotisButton.scss'

const RemotisButton = (props : RemotisButtonProps): JSX.Element => {
  const {children, ...buttonProps} = props;

  return (
    <Button {...buttonProps}> {children} </Button>
  );
}

export default RemotisButton;
