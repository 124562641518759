import { useEffect } from 'react';
import RemotisTable from 'common/components/remotis-tabel/RemotisTable';
import { useGetActiveTransactionsStatus , trimUsernameDomain , FormatAmount , useGetNetworkConfig } from 'common/libraries';
import { useGetTransactions } from './useGetTransactions';
import Moment from 'moment';
import RemotisChip from 'common/components/remotis-chip/RemotisChip';

import './PaymentAndBilling.scss';

const PaymentAndBilling = () => {

  const { success } = useGetActiveTransactionsStatus();
  const { network } = useGetNetworkConfig();
  const { isLoading, getTransactions, transactions } =
    useGetTransactions({});

  useEffect(() => {
    if (success) {
      getTransactions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    getTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function daysFromToday(timestamp:number) {
    const eventDate = Moment.unix(timestamp);
    const todayDate = Moment();

    return `${todayDate.diff(eventDate, 'days')} days`;
  }

  function truncate(string: string | number | null | undefined, nrCharacters = 7){
    if(string && typeof string === 'string') {
      return `${string.slice(0, nrCharacters)}...${string.slice(-nrCharacters)}`;
    }

    return string;
  }

  function convertStringToMxLink(id: string | number | null | undefined, link: string | undefined) {
    return  <a href={link} target="_blank" rel="noreferrer">{truncate(id)}</a>
  }

  const convertTransactionToTableFormat = () => {
    const tableData = transactions.map((transaction) => {
      return {
        "txHash": convertStringToMxLink(transaction?.txHash, transaction?.links.transactionLink),
        "age": daysFromToday(transaction?.timestamp),
        "from": transaction?.senderAssets?.name ?
          trimUsernameDomain(transaction?.senderAssets?.name) : truncate(transaction?.sender),
        "receiverShard" :transaction?.receiverShard,
        "to": trimUsernameDomain(transaction?.receiverAssets?.name),
        "senderShard": transaction?.senderShard,
        "sender": convertStringToMxLink(transaction?.sender, transaction?.links.senderLink),
        "receiver": convertStringToMxLink(transaction?.receiver, transaction?.links.receiverLink),
        "method": <RemotisChip label={transaction?.function} variant='outlined'></RemotisChip>,
        "value":  <FormatAmount
          className='menu__container__profile--item-description--text'
          value={transaction?.value}
          egldLabel={network.egldLabel}
          data-testid='balance'
          digits={2}
        />,
      }
    })

    return tableData;
  };

  if (!isLoading && transactions.length === 0) {
    return (
      <p>No transactions found</p>
    );
  }


  return (
    <div className="payment" id="payment">
      <RemotisTable
        data={convertTransactionToTableFormat()}
        header={[
          {title: 'txHash', id:'txHash'},
          {title: 'age', id:'age'},
          {title: 'to', id:'to'},
          {title: 'receiverShard', id:'receiverShard'},
          {title: 'from', id:'from'},
          {title: 'senderShard', id:'senderShard'},
          {title: 'sender', id:'sender'},
          {title: 'receiver', id:'receiver'},
          {title: 'method', id:'method'},
          {title: 'value', id:'value'}
        ]}>
      </RemotisTable>
    </div>
  );
};

export default PaymentAndBilling;
