import './LicenseCard.scss';
import { Container, Button } from '@mui/material';
import CardBox from 'common/components/card-box/CardBox';
import { License } from './LicenseCard.types';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { CropSquare, DoubleSquares, Formula1 } from 'assets/icons/Icons';
import { useState } from 'react';
import { useGetAccountInfo } from 'common/libraries';

const LicenseCard = ({
  licenseLevel,
  pricePerBattery,
  pricePerMonth,
  maxPower,
  fps,
  boostEachLap,
  carLiveries,
  logoLicense,
  status,
}: License): JSX.Element => {
  const [license, setLicense] = useState({
    month: pricePerMonth,
    battery: pricePerBattery,
  });

  const domain = import.meta.env.VITE_API_BASE_URL;
  const path = "payment/redirect";
  const  { account } = useGetAccountInfo();
  const sendMoney = async () => {
    window.open(
      `${domain}/${path}?
      price=${license.battery * pricePerBattery + license.month * pricePerMonth}&
      user_wallet=${account.address}&
      return_url=${ `${window.location.origin}/success-page`}`,
      "noreferrer"
    );
  };

  const handleMonthChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    setLicense({...license, month: Number(event.target.value)})
  };

  const handleBatteryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLicense({...license, battery: Number(event.target?.value)})
  };

  return (
    <CardBox>
      <div className="license-card">
        <div className='license-card__period-select'>
          <h2>{licenseLevel}</h2>
          <select onChange={handleMonthChange}>
            <option value="1">1 MONTHS</option>
            <option value="2">2 MONTHS</option>
            <option value="3">3 MONTHS</option>
            <option value="6">6 MONTHS</option>
            <option value="12">12 MONTHS</option>
          </select>
        </div>

        <Container className="license-card__container">
          <img className="license-card__container--logo-license" src={logoLicense} alt="" />
          <RemotisGrid container>
            <RemotisGrid item md={6}>
              <span>Battery:</span>
              <select className='body-text r-coin' onChange={handleBatteryChange}>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1500">1500</option>
                <option value="2000">2000</option>
              </select>
            </RemotisGrid>

            <RemotisGrid item md={6}>
              <span>Max Power: </span> <span className="body-text"> {maxPower} </span>{' '}
              <span className="rpm"> RPM </span>
            </RemotisGrid>
          </RemotisGrid>

          <div className="license-card__icons">
            <div className="license-card__icons--icon">
              <DoubleSquares />
              <div className="license-card__icons--icon-fps-group">
                <span>{fps}</span>
                <br />
                <span className="fps">FPS</span>
              </div>
            </div>

            <div className="license-card__icons--icon">
              <CropSquare />
              <div className="license-card__icons--icon-boost-group">
                <span>
                  BOOST <br />
                  EACH <br />
                  LAP
                </span>
                <span className="boost">{boostEachLap}</span>
                <br />
              </div>
            </div>

            <div className="license-card__icons--icon">
              <Formula1 className="formula1" />
              <div className="license-card__icons--icon-liveries-group">
                <span>{carLiveries} liveries</span>
                <br />
              </div>
            </div>

          </div>
          <Button disabled={status} onClick={sendMoney}>
            {license.battery * pricePerBattery + license.month * pricePerMonth} &#x20AC;
          </Button>
        </Container>
      </div>
    </CardBox>
  );
};

export default LicenseCard;
