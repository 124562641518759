import {
  ExtensionLoginButton,
  WalletConnectLoginButton,
  LedgerLoginButton,
  WebWalletLoginButton,
} from 'common/libraries';

import './LayoutWallet.scss';

const LayoutWallet = () => {
  return (
    <div className='wallet-container'>
      <WalletConnectLoginButton
        loginButtonText='xPortal App'
        buttonClassName = "MuiButton-root"
        modalClassName="wallet-modal"
        showScamPhishingAlert= {false} // optional
        wrapContentInsideModal = {true}
        innerWalletConnectComponentsClasses={{
          'containerContentClassName':'wallet-modal-container',
          'containerButtonClassName': 'wallet-modal-buttons',
          'containerLoaderClassName': 'wallet-modal-loader'
        }}
        callbackRoute = {"/my-profile"}
        nativeAuth = {true}
      />

      <LedgerLoginButton
        loginButtonText={'Ledger'}
        callbackRoute='/'
        className={'test-class_name'}
        buttonClassName = "MuiButton-root"
        modalClassName="wallet-modal"
        wrapContentInsideModal = {true}
        showScamPhishingAlert= {false}
        showProgressBar={false}
        innerLedgerComponentsClasses={{
          'ledgerProgressBarClassNames': {
            'ledgerProgressBarTrackClassName': 'wallet-modal-loader'
            // ledgerProgressBarThumbClassName?: string;
          },
          'ledgerLoadingClassNames': {
            'ledgerLoadingWrapper': 'wallet-modal-container',
            'ledgerLoadingSpinner': 'wallet-ledger-spinner'
          },
          'ledgerConnectClassNames': {
            'ledgerModalButtonClassName': 'MuiButton-root',
            'ledgerModalContentClassName': 'wallet-modal-container',
          }
        }}
      />

      <WebWalletLoginButton
        callbackRoute='/'
        loginButtonText={'Web wallet'}
        buttonClassName = "MuiButton-root"
      />

      <ExtensionLoginButton
        loginButtonText='DeFi Wallet`'
        className = "MuiButton-root"
        nativeAuth
      >
          DeFi Wallet
      </ExtensionLoginButton>
    </div>
  );
};

export default LayoutWallet;
