import './Activities.scss';
import CardBox from 'common/components/card-box/CardBox';
import React from 'react';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { ActivitiesProps } from 'common/components/activities/Activities.types';
import { Trophy, Flag, Medal, StopWatch, Hourglass, HelmetFill} from 'assets/icons/Icons';

const Activities = ({
  competitionsHeld,
  competitionsWon,
  medalsWon,
  freePracticeHeld,
  fastestLap,
  drivingHours,
}: ActivitiesProps): JSX.Element => {

  const activities = [
    {
      title: 'Competitions Held',
      value: competitionsHeld,
      icon: Flag
    },
    {
      title: 'Competitions won',
      value: competitionsWon,
      icon: Trophy
    },
    {
      title: 'Medals won',
      value: medalsWon,
      icon: Medal
    },
    {
      title: 'Free practices held',
      value: freePracticeHeld,
      icon: StopWatch
    },
    {
      title: 'Fastest lap',
      value: fastestLap,
      icon: HelmetFill
    },
    {
      title: 'Driving hours',
      value: drivingHours,
      icon: Hourglass
    }
  ];

  return (
    <CardBox>
      <div className="activities">
        <h2>ACTIVITIES</h2>
        <RemotisGrid container className="activities__container" >

          {activities.map((activity, key) => {
            return (
              <RemotisGrid key={key} item xs={4} >
                <activity.icon className="activities__container--icon" fontSize="large"  inheritViewBox/>
                <div className='activities__container__text'>
                  <span className='activities__container--label'>{activity.title}</span>
                  <h1>{activity.value}</h1>
                </div>
              </RemotisGrid>
            )
          })}

        </RemotisGrid>
      </div>
    </CardBox>
  );
};

export default React.memo(Activities);
