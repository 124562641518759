import { useQuery } from "react-query";
import { AxiosHeaders } from 'axios';
import { ApiLoaderProps, ApiRoutesGetProps } from './ApiLoader.types';
import Loader  from 'common/components/loader/Loader';
import Error  from './events/error/Error';
import { ApiGetRouteEnum } from "./ApiRouteEnum.enums"
import { useGetLoginInfo } from 'common/libraries';
import { getConfig, getRaceCalendar } from 'client-server-provider/api-service/ApiService'

const ApiLoader = (props: ApiLoaderProps)  => {

  const {Component, queryKey, params, ...restOfProps} = props;

  function WithApiLoader() {
    const { tokenLogin } = useGetLoginInfo();

    const switcher: ApiRoutesGetProps = {
      [ApiGetRouteEnum.getConfig]: getConfig,
      [ApiGetRouteEnum.getRaceCalendar]: getRaceCalendar,
    }

    const headers = AxiosHeaders.from({ 'Authorization': String(tokenLogin?.nativeAuthToken) });

    const { data, isError, isLoading, error } =  useQuery([queryKey], () =>
      switcher[queryKey](params, headers)
    );

    if (isLoading) {
      return <Loader/>;
    }

    if (isError) {
      return <Error error={error}/>
    }

    return (
      <Component apiResponse = {data} {...restOfProps} />
    );
  }

  return WithApiLoader;
};

export default ApiLoader;