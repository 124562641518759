import { Button } from '@mui/material';
import CardBox from 'common/components/card-box/CardBox';
import './CarOwner.scss';
import Car from 'assets/images/CarB.png';
import Car360 from 'assets/images/Car369.png';
import Championship from 'assets/images/Championship.png';
import Sessions from 'assets/images/Sessions.png';
import Setup from 'assets/images/Setup.png';
import Sponsors from 'assets/images/Sponsors.png';
import SpecCar from '../spec-car/SpecCar';
import Carousel from 'nuka-carousel';

const CarOwner = () => {
  const carOwnerDescriptions = [
    {
      title: 'Guida esclusiva',
      description:
        'Il legame che si instaura tra auto e pilota va al di là della comprensione di chi non è familiare al mondo dei motori. Curva dopo curva, la sintonia cresce! Solo utilizzando lo stesso veicolo ad ogni sessione si ha la possibilità di dargli confidenza, diventando un tutt’uno con la propria auto.',
      image: Car,
    },
    {
      title: 'Setup completo',
      description:
        'Accedi alla massima personalizzazione meccanica grazie al supporto di un tecnico, in grado di assisterti durante il setup della tua auto. Prova le modifiche in pista e richiedi interventi immediati al fine di creare l’assetto perfetto per il tuo stile di guida.',
      image: Setup,
    },
    {
      title: 'Accesso a campionati esclusivi',
      description:
        'Una volta diventato Car Owner la competizione ha inizio, goditi l’accesso ad una serie di eventi e campionati esclusivi nei quali affronterai i migliori piloti del mondo. Spunto (il vincitore riceve un anno di car owner gratis?).',
      image: Championship,
    },
    {
      title: 'Sessioni di test dedicate',
      description:
        'Per garantire una esperienza sempre più professionale, i Car Owner avranno slot dedicati per testare i propri setup prima di competere nelle gare più esclusive.',
      image: Sessions,
    },
    {
      title: 'Livrea personalizzabile',
      description:
        'Oltre ad avere una scocca racing, l’auto del car owner offre la possibilità di personalizzare la livrea digitale con sponsor o loghi ufficiali.',
      image: Sponsors,
    },
    {
      title: 'Sempre visibile 24/7',
      description:
        'La tua macchina sarà custodita all’interno di una teca su di uno stand rotante. Grazie ad un sofisticato sistema di camere, potrai vederla ogni volta che vorrai.',
      image: Car360,
    }
  ];
  return (
    <CardBox>
      <div className="car-owner">
        <div className="car-owner__container">
          <div className="car-owner__container--title">
            <span> Become a car owner </span>
          </div>

          <p className="car-owner__container--description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pellentesque sollicitudin
            neque nec consectetur. Curabitur pulvinar scelerisque augue, sed tristique dolor
            sollicitudin a. Vivamus condimentum erat eget lorem molestie semper. Testo descrittivo
            dei vantaggi legati al noleggio della macchina. Scocche dedicate EVO
          </p>
          <div className="car-owner__container--button">
            <hr></hr>
            <Button>Price</Button>
            <hr></hr>
          </div>
          <Carousel
            cellAlign={'center'}
            defaultControlsConfig={{
              nextButtonStyle: {
                display: 'none',
              },
              prevButtonStyle: {
                display: 'none',
              },
              pagingDotsContainerClassName: 'paging-dots-container',
              pagingDotsStyle: {
                display: 'block',
                fill: 'white',
                margin: '2px',
              },
            }}
          >
            {carOwnerDescriptions.map((carOwner, key) => {
              return (
                <SpecCar
                  key={key}
                  specTitle={carOwner.title}
                  description={carOwner.description}
                  image={carOwner.image}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </CardBox>
  );
};

export default CarOwner;
