import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import './LoginContent.scss';

const LoginContent = (): JSX.Element => {
  return (
    <div className="login-content">
      <RemotisGrid container className="login-content__container" >

        <RemotisGrid item xs={5} className="login-content__summary" >
          <div className="login-content__title">
            <span>Placeholder</span>
            <h1>title</h1>
            <hr></hr>
          </div>
        </RemotisGrid>

        <RemotisGrid item xs={5} className="login-content__description" >
          <div className="login-content__text">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed lorem mattis, maximus nulla sit amet, ullamcorper justo.
              Vivamus at augue gravida, porta mauris dapibus, mollis elit. Morbi dapibus enim vitae pharetra viverra. Nunc non nisl nunc.
              Praesent sollicitudin vestibulum blandit. Fusce malesuada.
            </span>
          </div>
        </RemotisGrid>

      </RemotisGrid>
    </div>
  );
};

export default LoginContent;
