import { Outlet } from 'react-router-dom';
import SubMenu from 'common/components/sub-menu/SubMenu';
import ElementSubMenu from 'common/components/element-sub-menu/ElementSubMenu';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { ShopLayoutProps } from './ShopLayout.types'
import './ShopLayout.scss';

const ShopLayout = ({ routes,  basePath}: ShopLayoutProps): JSX.Element => {

  return (
    <RemotisGrid container className="spacing">

      <SubMenu>
        {routes.map((route, key: number) => {
          return <ElementSubMenu path={`${basePath}/${route.path}`} menuTab={route.title} key={key} />;
        })}
      </SubMenu>

      <RemotisGrid container>
        <Outlet />
      </RemotisGrid>
    </RemotisGrid>
  );
};

export default ShopLayout;
