import Modal from '@mui/material/Modal';
import './MaterialModal.scss';
import { BasicModalProps } from './MaterialModal.types';


export default function MaterialModal({children, isOpen, closeHandle }: BasicModalProps): JSX.Element {
  return (
    <div>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={closeHandle}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="material-modal">
            {children}
          </div>
        </Modal>
      )}
    </div>
  );
}
