import { createContext, useContext } from "react";
import { BrokerConnectionContextProps, BrokerConnectionProps } from './Broker.types'
import Paho, { TypedArray } from 'paho-mqtt';
import { useGetAccountInfo } from 'common/libraries';
import callErrorToast from 'common/toasts/error-toast/ErrorToast';

const BrokerContext = createContext<BrokerConnectionContextProps | null>(null);

const BrokerConnection = ({ children }: BrokerConnectionProps): JSX.Element => {
  const  { account } = useGetAccountInfo();

  const client = new Paho.Client(String(import.meta.env.VITE_BROKER_IP), Number(import.meta.env.VITE_BROKER_PORT), "/ws", `front-end-app-${account.address}`);

  // set callback handlers
  client.onConnectionLost = onConnectionLost;
  client.onMessageArrived = onMessageArrived;

  // connect the client
  client.connect({
    onSuccess: onConnect,
    useSSL: import.meta.env.VITE_BROKER_USE_SSL === 'true',
    keepAliveInterval: 10 // seconds
  });

  // called when the client connects
  function onConnect() {
    console.log("Broker On Connect");
  }

  const sendData = (data:string | ArrayBuffer | TypedArray) => {
    const message = new Paho.Message(data);
    message.destinationName = "mkr.7";

    try {
      client.send(message);
    } catch (error:unknown) {
      callErrorToast(`Broker: ${String(error)}`)
    }

  }

  // called when the client loses its connection
  function onConnectionLost(responseObject: { errorCode: number; errorMessage: string; }) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:" + responseObject.errorMessage);
    }
  }

  // called when a message arrives
  function onMessageArrived(message: { payloadString: string; }) {
    console.log("onMessageArrived:" + message.payloadString);
  }

  return (
    <BrokerContext.Provider value={{sendData}}>{children}</BrokerContext.Provider>
  );
}

export default BrokerConnection;

export const useBrokerContext = () => {
  return useContext(BrokerContext)
}