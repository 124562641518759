import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import "./LinearLoader.scss"
import {LinearLoaderProps} from "./LinearLoader.types"

const LinearLoader = ({value}:LinearLoaderProps): JSX.Element => {
  return (
    <div className='linear-loader'>
      <Typography className='linear-loader__loading-text'>
        Loading
        <span className="linear-loader__loading-value"> {Math.round(value)}% </span>
      </Typography>
      <LinearProgress value={value} className='linear-loader__loader' variant="determinate" />
    </div>
  );
}

export default LinearLoader;