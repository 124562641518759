import Slider from '@mui/material/Slider';
import { PositionSliderProps } from './PositionSlider.types'

import './PositionSlider.scss'

export default function PositionSlider({value} : PositionSliderProps) : JSX.Element {

  return (
    <Slider
      key={`slider-${value}`}
      track={false}
      aria-labelledby="track-false-slider"
      value={Number(value)}
    />
  );
}

