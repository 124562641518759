import './Z1Ring.scss';
import Z1ring from 'assets/images/Z1ring.png';
import CircuitZ1 from 'assets/images/CircuitZ1.png';
import CardBox from 'common/components/card-box/CardBox';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import RemotisButton from 'common/components/remotis-button/RemotisButton';
import { useNavigate } from 'react-router-dom';
import { SingleRaceIcon } from 'assets/icons/Icons';
import React from 'react';

const Z1Ring = () => {
  const navigate = useNavigate();
  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(`trackIntroduction`);
  };
  return (
    <RemotisGrid item>
      <CardBox>
        <RemotisGrid container id="z1-ring__container" className="z1-ring__container">
          <RemotisGrid item xs={4}>
            <img src={Z1ring} alt="RM8" />
          </RemotisGrid>
          <RemotisGrid item xs={8}> </RemotisGrid>
          <RemotisGrid item xs={4}>
            <p className="z1-ring__container--title">CIRCUIT HISTORY</p>
            <p className="z1-ring__container--text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel magna facilisis,
              sodales ipsum lacinia, cursus nunc. Sed fringilla libero non congue aliquam.
            </p>
            <p className="z1-ring__container--title">FULL FORWARD LAYOUT</p>
            <p className="z1-ring__container--text">
              Phasellus eu tortor eu mi finibus bibendum vitae sit amet magna. Aenean ac est quis
              sapien dignissim sagittis non id enim. Praesent ac sapien sit amet neque ultricies
              porttitor.˝
            </p>
          </RemotisGrid>
          <RemotisGrid item xs={8}>
            <div className="z1-ring__car-description">
              <div className="z1-ring__car-description--type-race">
                <div>
                  <p>CLOCKWISE</p>
                </div>
                <span>{React.createElement(SingleRaceIcon)}</span>
                <div>{React.createElement(SingleRaceIcon)}</div>
                <div>{React.createElement(SingleRaceIcon)}</div>
                <div>{React.createElement(SingleRaceIcon)}</div>
              </div>
              <div className="z1-ring__car-description--type-race">
                <div>
                  <p>REVERSE</p>
                </div>
                <span>{React.createElement(SingleRaceIcon)}</span>
                <div>{React.createElement(SingleRaceIcon)}</div>
                <div>{React.createElement(SingleRaceIcon)}</div>
                <div>{React.createElement(SingleRaceIcon)}</div>
              </div>
              <img src={CircuitZ1} alt="CircuitZ1" className="car" />
            </div>
          </RemotisGrid>
          <RemotisGrid item xs={12} className="track-introduction">
            <RemotisButton onClick={clickHandler}> Track introduction </RemotisButton>
          </RemotisGrid>
        </RemotisGrid>
      </CardBox>
    </RemotisGrid>
  );
};

export default Z1Ring;
