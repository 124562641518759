import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { AxiosError  } from 'axios';

import './Error.scss'

export interface ErrorProps {
    error: unknown
}

const Error = ({error}: ErrorProps)  => {
  return (
    <Box className="error">
      <Alert className="error_alert" severity="error">
        {error instanceof AxiosError && <span> {error.code}: {error.message}</span>}
      </Alert>
    </Box>
  );
}

export default Error;