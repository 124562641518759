import Activities from 'my-profile/components/activities/Activities';
import Replays from 'my-profile/components/replays/Replays';
import CarSetup from 'my-profile/components/car-setup/CarSetup';
import License from 'my-profile/components/license/License';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import './MyProfileLayout.scss';

import { Link } from 'react-router-dom';

const MyProfileLayout = () => {

  return (
    <div className="my-profile">

      <RemotisGrid container >

        <RemotisGrid item  xs={4} >
          <License
            licenseLevel="Amateur"
            monthlyRcoins={12}
            maxPower={1000}
            fps={12}
            boostEachLap={1}
            carLiveries={1}
          />
        </RemotisGrid>

        <RemotisGrid item  xs={8}>
          <Activities
            competitionsHeld={12}
            competitionsWon={122}
            medalsWon={1}
            freePracticeHeld={11}
            fastestLap={2}
            drivingHours={33}
          />
        </RemotisGrid>

        <RemotisGrid item  xs={8} >
          <Replays />
        </RemotisGrid>

        <RemotisGrid item  xs={4} >
          <Link to={'car-setup/mechanics'}>
            <CarSetup
              painting="B.Red"
              material='Carbon'
              sound="V8"
            />
          </Link>
        </RemotisGrid>

      </RemotisGrid>
    </div>
  );
};

export default MyProfileLayout;
