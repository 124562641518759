import React from "react";
import { paths } from "remotisApi.types";
import { useQueryClient, useMutation } from "react-query";
import { ApiStateMutatorRequestProps } from './ApiStateMutator.types';
import { useGetLoginInfo } from 'common/libraries';
import { AxiosHeaders } from 'axios';
import type { AxiosRequestHeaders } from 'axios';
import callLoadingToast, { deleteLoadingToast } from 'common/toasts/loading-toast/LoadingToast';
import callSuccessToast from 'common/toasts/success-toast/SuccessToast';

export const useApiStateMutator = (
  request:(
    data: unknown,
    queryParams?:paths['/v1/api/config']['parameters']['query'],
    headers?:AxiosRequestHeaders
  ) => Promise<Response>
) => {
  const queryClient = useQueryClient()
  const { tokenLogin } = useGetLoginInfo();

  const [state, setState] = React.useState({
    data: {},
    params: {},
    key: '',
  });

  function mutate(data:ApiStateMutatorRequestProps) {
    callLoadingToast();
    setState(data);
  }

  const firstUpdate = React.useRef(true);

  const mutator =  useMutation(
    () => {
      return request(state.data, state.params, new AxiosHeaders({'Authorization': String(tokenLogin?.nativeAuthToken)}))
    },
    {
      onSuccess: () => {
        deleteLoadingToast();
        callSuccessToast('Configuration Saved');
      },
      onSettled: () => {
        queryClient.invalidateQueries([state.key]);
      }
    }
  );

  React.useEffect(() => {
    if(firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    mutator.mutate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return mutate;
}
