import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { LoaderProps } from './Loader.types'
import './Loader.scss'

const Loader = (props : LoaderProps): JSX.Element => {
  return (
    <Box className='loader'>
      <CircularProgress {...props} className='loader_progress'/>
    </Box>
  );
}

export default Loader;