import RemotisButton from 'common/components/remotis-button/RemotisButton'
import ApiLoader from 'client-server-provider/api-loader/ApiLoader';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import UserInteractionsMessages from './user-interactions-messages/UserInteractionsMessages';
import ConfigStateMessages from './config-state-messages/ConfigStateMessages';
import GamepadStatus from './gamepad-status/GamepadStatus';
import { useCallback, useEffect, useRef, useState } from 'react';
import { gamepadSelectedKeyProps } from './GameController.types';
import { setConfig } from 'client-server-provider/api-service/ApiService'
import { ApiResponseProps } from 'client-server-provider/api-loader/ApiLoader.types';
import { useApiStateMutator } from 'client-server-provider/api-state-mutator/ApiStateMutator';
import { ApiGetRouteEnum, ApiPostRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';
import { findGamepadInteraction, FoundedInteractionDetail } from 'helpers/FindGamepadInteraction'

import './GameController.scss';
import { Checkbox } from '@mui/material';

const GameController = ({ apiResponse }: ApiResponseProps): JSX.Element => {
  const [gamepads, setGamepads] = useState<(Gamepad | null)[]>([]);
  const gamepadCompareRef = useRef<(Array<Gamepad | null>)>([]);
  const timestampRef = useRef<Array<number | undefined>>([]);
  const requestRef = useRef<number>(0);
  const [newButtonSelected, setNewButtonSelected] = useState<(boolean)>(false);
  const [currentUpdatingAction, setCurrentUpdatingAction] = useState<string | null>(null);
  const [actionUpdated, setActionUpdated] = useState(false);
  const gamepadCurrentConfig = useRef<gamepadSelectedKeyProps>({});
  const currentGamepadInitialState = useRef<Array<Gamepad | null>>([]);
  const [gamepadCurrentState, setGamepadCurrentState] = useState<Array<Gamepad | null>>([]);
  const requestAnimationFrame = window.requestAnimationFrame || window.requestAnimationFrame || window.requestAnimationFrame;
  const setApiState = useApiStateMutator(setConfig);

  const [reversedThrottle, setReversedThrottle] = useState(false);
  const handleThrottleRevert = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const throttleRevered = gamepadCurrentConfig.current['gamepad.control.throttleReversed'];

    if(throttleRevered?.id) {
      setApiState({
        data:{id:throttleRevered.id, value: String(checked)},
        params:{id: throttleRevered.id},
        key: ApiPostRouteEnum.setConfig,
      })
    }
  };

  // send to api current gamepad config state
  const saveGamepadActions = (id: number | null, value:unknown, hash: string) => {
    // todo add to params controller id(specification not defined)
    // custom hook for using react-query mutation hook with params
    if(id) {
      setApiState({
        data:{id:id, value:value, hash: hash},
        params:{id:id},
        key: ApiPostRouteEnum.setConfig,
      })
    }
  }

  // check if user interacted with the gamepad and pressed dessired button
  function waitingGamepadInteraction() {
    return new Promise((resolve) => {

      const gamepadCurrent = window.navigator.getGamepads();
      if(gamepadCurrent.length === 0) {
        return;
      }

      const foundedInteractionDetail = findGamepadInteraction(gamepadCurrent, currentGamepadInitialState.current)

      if(foundedInteractionDetail.index !== -1) {
        resolve(foundedInteractionDetail);
      }
      if(foundedInteractionDetail.index === -1) {
        Promise.reject('Key Not Found');
      }
    });
  }

  // create a loop that awaits for user to press a button
  const waitingGamepadInteractionLoop = () => new Promise<FoundedInteractionDetail>((resolve) => {
    // repeat interval until the promise is resolved
    const interval = setInterval(() => {
      waitingGamepadInteraction().then((value) => {
        clearInterval(interval);
        resolve(value as FoundedInteractionDetail);
      });
    }, 500);
  });

  const configSelector = async (e: { target: { id: string; }; }) => {
    const selectedAction = e.target.id;

    if (!selectedAction && gamepads.length !== 0)
      return;

    setCurrentUpdatingAction(selectedAction)

    // set initial state before comparing
    currentGamepadInitialState.current = window.navigator.getGamepads();
    // create a loop that awaits for user to interact with the gamepad and select wanted button for the selected action
    waitingGamepadInteractionLoop()
      .then((value:FoundedInteractionDetail) => {
        setActionUpdated(true);

        setTimeout(() => {
          setActionUpdated(false);
          setCurrentUpdatingAction(null);
        }, 1000)


        let gamepadSelectedPosition = `[${value.type}][${value.index}]`

        if(value.type === 'buttons'){
          gamepadSelectedPosition = gamepadSelectedPosition.concat(`[value]`)
        }

        const current:gamepadSelectedKeyProps = {[selectedAction]:{'value':gamepadSelectedPosition, 'id':null, 'hash':value.hash}};

        // search if selected button is assigned to other action
        const alreadyAssignedKey = Object.keys(gamepadCurrentConfig.current).find(key => gamepadCurrentConfig.current[key].value === gamepadSelectedPosition);

        // create a copy of the prevState to mutate before save
        const copy:gamepadSelectedKeyProps = {...gamepadCurrentConfig.current};

        // if user select the same button for other action, remove button from the old action
        if (alreadyAssignedKey && alreadyAssignedKey !== selectedAction ) {
          copy[alreadyAssignedKey].value = '';
        }
        // copy the id, of the old action into the new button config, used to update the action if it was saved
        if (gamepadCurrentConfig.current[selectedAction]) {
          saveGamepadActions(copy[selectedAction].id, gamepadSelectedPosition, value.hash);
          current[selectedAction].id = copy[selectedAction].id;
        }
        // combine old state with the new config action
        gamepadCurrentConfig.current = {...gamepadCurrentConfig.current, ...current};
      })
      .catch(() => console.log("error"));
  }

  const runAnimation = useCallback(() => {
    const gamepads = window.navigator.getGamepads();

    const gamepadMoved = gamepads.some(
      (e, index) => (e?.timestamp && Number(timestampRef.current[index]) < e?.timestamp) ||
      timestampRef.current.length === 0
    )
    if (gamepads && gamepadMoved) {
      const foundedInteractionDetail = findGamepadInteraction(gamepads, gamepadCompareRef.current)

      let found = false;
      found = Object.values(gamepadCurrentConfig.current).some((item, gamepadIndex) =>
        (foundedInteractionDetail.type === 'axes' &&
            item.value === `[axes][${foundedInteractionDetail.index}]` &&
            item.hash === foundedInteractionDetail.hash)
            ||
          (foundedInteractionDetail.type === 'buttons' &&
            item.value === `[buttons][${foundedInteractionDetail.index}][value]` &&
            item.hash === foundedInteractionDetail.hash)
      )

      if(foundedInteractionDetail.index === -1) {
        setNewButtonSelected(found);
      }

      if(foundedInteractionDetail.index != -1 && !found) {
        setNewButtonSelected(true);
      }

      timestampRef.current = gamepads.map(gamepad => gamepad?.timestamp) ?? [];
      gamepadCompareRef.current = gamepads;
      setGamepadCurrentState(gamepads)

    }
    requestRef.current = requestAnimationFrame(runAnimation);
  }, [requestAnimationFrame])

  const connect = useCallback((e:Event | GamepadEvent) => {
    if (e instanceof GamepadEvent) {
      console.log(
        "Gamepad connected at index %d: %d buttons, %d axes.",
        e.gamepad.index, e.gamepad.buttons.length, e.gamepad.axes.length
      );
    }
    const gamepads = window.navigator.getGamepads();
    gamepadCompareRef.current = gamepads;
    setGamepads(gamepads);

    // reset timestampRef on each connect
    // when new device is connected we need to add the new timestamps for the new one
    // this will call run animation and update with the new timestampRef for each device
    timestampRef.current = [];

    if(!requestRef.current)
      requestRef.current = requestAnimationFrame(runAnimation);

  }, [requestAnimationFrame, runAnimation])

  const connectedGamepadNumber = () => {
    const number = gamepads.filter(n => n).length;

    return <>{number}</>;
  }

  const disconnect = useCallback((e:Event | GamepadEvent) => {
    if (e instanceof GamepadEvent) {
      const gamepadsNow = window.navigator.getGamepads();
      setGamepads(gamepadsNow);

      console.log("Gamepad disconnected from index %d.", e.gamepad.index);

      // reset timestampRef on each disconnect
      // when a device is disconnect we need to add the new timestamps for the new one
      // this will call run animation and update with the new timestampRef for each device
      timestampRef.current = [];

      const connectedGamepad = gamepadsNow.filter(n => n).length;
      // cancel only when all gamepads have been disconnected
      if(connectedGamepad === 0) {
        cancelAnimationFrame(requestRef.current ?? 0);
        // delete requestRef id if the animation was cancelled
        requestRef.current = 0;
      }
    }
  }, []);

  useEffect(() => {
    let saved:gamepadSelectedKeyProps = {};
    if (apiResponse && apiResponse.constructor == Array) {
      apiResponse.forEach(element => {
        saved = {...saved, [element.key]:{'value':element.value, 'id':Number(element.id), hash: element.hash}}
      });
    }

    gamepadCurrentConfig.current = {...gamepadCurrentConfig.current, ...saved}
    setReversedThrottle(saved['gamepad.control.throttleReversed']?.value === 'true' ? true : false);
    connect(new Event("init"));
    window.addEventListener("gamepadconnected", connect);
    window.addEventListener("gamepaddisconnected", disconnect);

    return () => {
      window.removeEventListener("gamepadconnected", connect);
      window.removeEventListener("gamepaddisconnected", disconnect);
      if(!requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  },[apiResponse, connect, disconnect]);

  return (
    <div className="game-controller-wizard">
      <RemotisGrid container>
        <RemotisGrid item xs={6}>
          <div className='game-controller-wizard__config'>
            <div className='game-controller-wizard__config--buttons'>

              <div className="game-controller-wizard__actions">
                <RemotisButton
                  disabled={gamepads.length === 0}
                  className='game-controller-wizard__actions--button'
                  onClick={configSelector}
                  variant="contained"
                  id='gamepad.control.brake'
                >
                  Brake

                </RemotisButton>
                <UserInteractionsMessages
                  actionUpdated={actionUpdated}
                  id='throttle'
                  currentUpdatingAction={currentUpdatingAction}
                  apiResponse={apiResponse}
                  gamepadCurrentState={gamepadCurrentState}
                  gamepadCurrentConfig={gamepadCurrentConfig.current}
                />
                <RemotisButton
                  disabled={gamepads.length === 0}
                  className='game-controller-wizard__actions--button game-controller-wizard__actions--speed'
                  onClick={configSelector}
                  variant="contained"
                  id='gamepad.control.speed'
                >
                  Speed

                </RemotisButton>

                <Checkbox
                  checked={reversedThrottle}
                  onChange={handleThrottleRevert}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div key='gamepad.control.turbo' className="game-controller-wizard__actions">
                <RemotisButton
                  disabled={gamepads.length === 0}
                  className='game-controller-wizard__actions--button'
                  onClick={configSelector}
                  variant="contained"
                  id='gamepad.control.turbo'
                >
                  Turbo

                </RemotisButton>
                <UserInteractionsMessages
                  actionUpdated={actionUpdated}
                  id='gamepad.control.turbo'
                  currentUpdatingAction={currentUpdatingAction}
                  apiResponse={apiResponse}
                  gamepadCurrentState={gamepadCurrentState}
                  gamepadCurrentConfig={gamepadCurrentConfig.current}
                />
              </div>
              <div key='gamepad.control.steering' className="game-controller-wizard__actions">
                <RemotisButton
                  disabled={gamepads.length === 0}
                  className='game-controller-wizard__actions--button'
                  onClick={configSelector}
                  variant="contained"
                  id='gamepad.control.steering'
                >
                  Steering

                </RemotisButton>
                <UserInteractionsMessages
                  actionUpdated={actionUpdated}
                  id='gamepad.control.steering'
                  currentUpdatingAction={currentUpdatingAction}
                  apiResponse={apiResponse}
                  gamepadCurrentState={gamepadCurrentState}
                  gamepadCurrentConfig={gamepadCurrentConfig.current}
                />
              </div>
              <div className="game-controller-wizard__actions">
                <RemotisButton
                  disabled={gamepads.length === 0}
                  className='game-controller-wizard__actions--button'
                  onClick={configSelector}
                  variant="contained"
                  id='gamepad.control.headLights'
                >
                  headLights

                </RemotisButton>
                <UserInteractionsMessages
                  actionUpdated={actionUpdated}
                  id='gamepad.control.headLights'
                  currentUpdatingAction={currentUpdatingAction}
                  apiResponse={apiResponse}
                  gamepadCurrentState={gamepadCurrentState}
                  gamepadCurrentConfig={gamepadCurrentConfig.current}
                />
              </div>

            </div>
          </div>
        </RemotisGrid>
        <RemotisGrid item xs={6}>
          <div className='game-controller-wizard__info'>
            {gamepads.length !== 0 &&
              <>
                <p className='game-controller-wizard__title'>How to change buttons</p>
                <p className='game-controller-wizard__help-text'>To change the button, click the action you would like to change and make the assignment by pressing the desired button</p>
                <ConfigStateMessages
                  newButtonSelected ={newButtonSelected}
                  apiResponse={apiResponse}
                />
              </>
            }
            <GamepadStatus gamepads={gamepads} />

            <RemotisButton disabled={gamepads.length === 0} variant="contained"> Reset to default </RemotisButton>
          </div>
        </RemotisGrid>
        <RemotisGrid item xs={6}>
            Number of connected gamepad {connectedGamepadNumber()}
        </RemotisGrid>
      </RemotisGrid>
    </div>
  );
};

export default ApiLoader({
  Component: GameController,
  params: {key: 'gamepad.control'},
  queryKey: ApiGetRouteEnum.getConfig
});
