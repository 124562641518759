import './RemotisRM.scss';
import RM8 from 'assets/images/RM8.png';
import CarRM81 from 'assets/images/CarRM81.png';
import CardBox from 'common/components/card-box/CardBox';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';

const RemotisRM = () => {
  return (
    <RemotisGrid item>
      <CardBox>
        <RemotisGrid container id='remotis-rm__container' className="remotis-rm__container">
          <RemotisGrid item xs={4}>
            <img src={RM8} alt="RM8" />
          </RemotisGrid>
          <RemotisGrid item xs={8}>
            <div className="remotis-rm__description">
              <div>
                <p>Scale:</p>
                <p className="remotis-rm__description--value"> 1/8 </p>
              </div>
              <div>
                <p>Traction:</p>
                <p className="remotis-rm__description--value"> 4WD </p>
              </div>
              <div>
                <p>Mac RPM:</p>
                <p className="remotis-rm__description--value"> 30.000 </p>
              </div>
              <div>
                <p>Wheight:</p>
                <p className="remotis-rm__description--value">
                  4.500<span>G</span>
                </p>
              </div>
            </div>
          </RemotisGrid>
          <RemotisGrid item xs={4}>
            <p className="remotis-rm__container--title">CAR STRUCUTRE</p>
            <p className="remotis-rm__container--text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel magna facilisis,
              sodales ipsum lacinia, cursus nunc. Sed fringilla libero non congue aliquam.
            </p>
            <p className="remotis-rm__container--text">
              Phasellus eu tortor eu mi finibus bibendum vitae sit amet magna. Aenean ac est quis
              sapien dignissim sagittis non id enim. Praesent ac sapien sit amet neque ultricies
              porttitor.˝
            </p>
          </RemotisGrid>
          <RemotisGrid item xs={8}>
            <div className="remotis-rm__car-description">
              <img src={CarRM81} alt="CarRM8" className="car" />
            </div>
          </RemotisGrid>
        </RemotisGrid>
      </CardBox>
    </RemotisGrid>
  );
};

export default RemotisRM;
