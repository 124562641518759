import { RemotisTableProps } from './RemotisTable.types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import './RemotisTable.scss';


const RemotisTable = (props : RemotisTableProps): JSX.Element => {
  const {data, header} = props;

  return (
    <div className='remotis_table'>
      <TableContainer>
        <Table aria-label="sticky table" stickyHeader>
          <TableHead>
            <TableRow>
              {header?.map((value: {title:string, id:string}, i:string | number) => (
                <TableCell align="center" className="remotis_table--cell"  key={i}>{value.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((value, i:string | number) => (
              <TableRow key={i}>
                {header.map((keyName: {title:string, id:string}, key:string | number) => {
                  return(
                    <TableCell align="center" className="remotis_table--body-cell" key={key} >{value[keyName.id]}</TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default RemotisTable;
