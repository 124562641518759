import { useWebRtcContext } from "webrtc/WebRtcConnection";
import { Slide } from "@mui/material";
import Fade from '@mui/material/Fade';
import LinearLoader from "common/components/linear-loader/LinearLoader"
import Hud from "live-race/components/hud/Hud"
// import ScoreBoard from "live-race/components/score-board/ScoreBoard";
// import RacerBoard from "live-race/components/racer-board/RacerBoard";
import MirrorBoard from "live-race/components/mirror-board/MirrorBoard";
import ApiLoader from "client-server-provider/api-loader/ApiLoader";
import {videoAspectRatioChanger} from 'helpers/VideoFormatter'
import { useCallback, useEffect, useMemo } from "react";
import Gamepad from "gamepad/Gamepad";
import { useLocation , createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Moment from 'moment';
import { LiveRaceProps } from './LiveRace.types'
import { ApiGetRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';

import LayoutRight from 'assets/images/test-images/Remotis_HUD_3840x1600_RightA.png';
import LayoutLeft from 'assets/images/test-images/Remotis_HUD_3840x1600_LeftA.png';

import LayoutRightSmall from 'assets/images/test-images/Remotis_HUD_1920x1080_RightA.png';
import LayoutLeftSmall from 'assets/images/test-images/Remotis_HUD_1920x1080_LeftA.png';

import RoundedShadow from 'assets/images/test-images/Remotis_HUD_1920x1080_Vignetting.png';
import { ConfigNamesEnum } from 'local-constants/configs/config.enums';


import './LiveRace.scss'

const CONFIG_KEY = 'video.aspect.ratio';

// const racers = [
//   {
//     position: 1,
//     name: 'The Doctor',
//     time: '+0.768'
//   },
//   {
//     position: 4,
//     name: 'Pain',
//     time: '+0.898'
//   }
// ]

// const racerBoard = {
//   position: 6,
//   player_number: 12,
//   lap_number: 6,
//   lap_total: 6,
//   time: [
//     {
//       current: 68.307,
//     },
//     {
//       current: 68.307,
//       time_improve: '+0.59'
//     }
//   ],
//   current_lap_time: {
//     time: 68.307,
//     time_improve: '+0.59'
//   },
//   current_lap: 1
// }

const LiveRace = ({ apiResponse, controls, gamepad }: LiveRaceProps): JSX.Element => {

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const ip = query.get("ip");
  const webrtc = useWebRtcContext();

  useEffect(() => {
    if(webrtc && ip) {
      webrtc.createPeer(ip);
    }
    return () => {
      // restart connection
      if(webrtc && ip) {
        webrtc.createPeer(ip, 'close');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ip]);

  const videoAspectRation = useCallback(() => {
    if (apiResponse && apiResponse.constructor == Array) {
      const aspectRatio = apiResponse.find((element) => element.key == CONFIG_KEY);
      if (aspectRatio && Number(aspectRatio.value) > 0) {
        videoAspectRatioChanger('frontCamera', Number(aspectRatio.value))
      }
    }
  }, [apiResponse])

  const centralConsolePosition = () => {
    const carConsole = document.getElementById("car-console");
    const video = document.getElementById("frontCamera") as HTMLVideoElement;

    if (!video || !carConsole)
      return

    const videoRatio = video.videoWidth / video.videoHeight;
    let width = window.innerWidth;
    let height = window.innerHeight;
    const elementRatio = width / height;
    // If the video element is short and wide
    if(elementRatio > videoRatio)
    {
      width = height * videoRatio;
    } else {
      height = width / videoRatio;
    }

    // 2.8 is the proportion of the car in the video
    carConsole.style.width = width / 2.8 + "px";
    // /2 because camera has equal white spaces on every side
    const newPosition = (window.innerHeight - height) / 2;
    if(newPosition > 0) {
      carConsole.style.bottom =  newPosition +  "px";
    } else {
      carConsole.style.bottom =  0 + "px";
    }
  }

  const setVibrationMagnitude = (accelerometer: number, gamepads: Array<Gamepad | null> | undefined) => {
    let threshold = 10;

    if (apiResponse && apiResponse.constructor == Array) {
      const vibrationApi = apiResponse.find((element) => element.key == "mechanic.vibration.level");
      if(vibrationApi?.value) {
        threshold = Number(vibrationApi.value);
      }
    }
    if(accelerometer > threshold) {
      gamepads?.forEach((element) =>
      // IMPORTANT: not all gamepads support vibration!
      // experimental function and is missing in gamepad lib(workaround)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        element?.vibrationActuator?.playEffect("dual-rumble", {
          startDelay: 0,
          duration: 50,
          weakMagnitude: 1,
          strongMagnitude: 1,
        })
      );
    }
  }

  {/* test - remove after testing */}
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const goBackToCalendar = () => {
    const date = Moment(searchParams.get('date')).format('yyyy-MM-DD HH:mm:ss');
    const params = { date: date};

    navigate({
      pathname: '/racing/reserve',
      search: `?${createSearchParams(params)}`,
    });
  }

  const rpm = webrtc ? webrtc.data['live-data'].rpm : 0 ;
  const outputPowerPercentage = webrtc ? webrtc.data['live-data'].outputPowerPercentage : 0;
  const speed = controls ? controls[ConfigNamesEnum.speed] : 0;
  const brake = controls ? controls[ConfigNamesEnum.brake] : 0;
  const turbo = controls ? controls[ConfigNamesEnum.turbo] : false;
  const sockSensor =  webrtc ? webrtc.data['live-data'].sockSensor : 9.8;

  // const outputVoltage =  webrtc ? webrtc.data['esc'].outputVoltage : 0;
  // const inputVoltage =  webrtc ? webrtc.data['esc'].inputVoltage : 0;
  // const temp =  webrtc ? webrtc.data['esc'].temp : 0;

  useEffect(() => {
    window.addEventListener("resize", centralConsolePosition);
    window.addEventListener("resize", videoAspectRation);
    const v = document.getElementById("frontCamera");
    const body = document.body;
    if(v) {
      v.onplaying = function () {
        // body.requestFullscreen = body.requestFullscreen || function () { return false; };
        body.requestFullscreen();
      };
    }

    setVibrationMagnitude(sockSensor, gamepad)


    return () => {
      window.removeEventListener("resize", centralConsolePosition);
      window.removeEventListener("resize", videoAspectRation);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoAspectRation, sockSensor]);

  return (
    <div className="car-garage">

      <Slide direction="down"in={webrtc && webrtc.loading < 100 ? false : true} timeout={500}>
        <div>
          <img src={RoundedShadow} className="rounded-shadow" alt="rounded-shadow" />
          <div className="car-garage__top-container">
            {/* test - remove after testing */}
            <ArrowBackIosIcon
              className="car-garage__go-back-icon"
              onClick={goBackToCalendar}
            />

            <div className="car-garage__top-container--score-board">
              {/* <ScoreBoard racers={racers.concat({
            position: 7,
            name: 'Output Voltage',
            time: outputVoltage.toFixed(3)
          }).concat({
            position: 8,
            name: 'Input Voltage',
            time: inputVoltage.toFixed(3)
          })} /> */}
              <img src={LayoutLeft} className="layout-left layout-left-big" alt="layout-left" />
              <img src={LayoutLeftSmall} className="layout-left layout-left-small" alt="layout-left" />
            </div>

            <div className="car-garage__top-container--mirror-content">
              <MirrorBoard/>
            </div>


            <div className="car-garage__top-container--racer-details">
              {/* <RacerBoard racerBoard={racerBoard}/> */}
              <img src={LayoutRight} className="layout-right layout-right-big" alt="layout-right" />
              <img src={LayoutRightSmall} className="layout-right layout-right-small" alt="layout-right" />
            </div>
          </div>
        </div>
      </Slide>

      <Fade appear={false} in={webrtc && webrtc.loading < 100 ? true : false} timeout={2000}>
        <div className="car-garage__loading-cover">
          <div className="car-garage__loader">
            <LinearLoader value={webrtc ? webrtc.loading : 0}/>
          </div>
        </div>
      </Fade>


      <Fade in={webrtc && webrtc.loading < 100 ? false : true} addEndListener={videoAspectRation}>
        <div>
          <video muted={true} className="car-garage__front-camera" id="frontCamera"><track kind="captions"/></video>
        </div>
      </Fade>

      <Slide direction="up" in={webrtc && webrtc.loading < 100 ? false : true} timeout={500} addEndListener={centralConsolePosition}>
        <div className="car-garage__car-console" id="car-console">
          <Hud turbo={turbo} speed={speed} brake={brake} rpm={rpm} outputPowerPercentage={outputPowerPercentage}/>
        </div>
      </Slide>
    </div>
  );
};

const LiveRaceWithGamepad = Gamepad(LiveRace);

// wrapper(high order component) that provide api response based on method provided from api service
export default ApiLoader({
  Component: LiveRaceWithGamepad,
  params: {},
  queryKey: ApiGetRouteEnum.getConfig
});



