import replay from 'assets/images/replay.png';
import thumbnail from 'assets/images/replay-thumbnail.png';
import { VideoProps } from './ReplayVideo.types';
import LicenseAmateur from 'assets/images/license-amateur.svg?react';
import './ReplayVideo.scss';

const ReplayVideo = ({ date, startTime, endTime }: VideoProps): JSX.Element => {
  return (
    <div className="replay-video">
      <img src={thumbnail} className="replay-video--thumbnail" alt="Logo" />

      <div className="replay-video__banner">
        <img src={replay} className="replay-video__logo" alt="Logo" />
        <div className="replay-video__banner--date">
          <p className='body-text'> {date} </p>
          <p>{startTime} - {endTime} </p>
        </div>
        <LicenseAmateur className="replay-video__banner--image"/>
      </div>

    </div>
  );
};

export default ReplayVideo;
