import { Box, Container } from '@mui/material';
import React from 'react';
import './GridSpacing.scss';

import CalendarTime from 'common/components/calendar-time/CalendarTime';
import ResponsiveDrawer from 'common/components/menu-burger/MenuBurger';

interface GridSpacingProps {
  children: React.ReactNode;
}

const GridSpacing = ({ children }: GridSpacingProps): JSX.Element => {
  return (
    <div className=" grid-spacing ">
      <ResponsiveDrawer />

      <div className="background">
        <Box className="main-grid">
          <Container maxWidth={'xxl'} className="background__container" disableGutters={true}>
            <CalendarTime />
            <Box sx={{ alignItems: 'center' }}>{children}</Box>
          </Container>
        </Box>
      </div>
    </div>
  );
};

export default GridSpacing;
