import Grid from '@mui/material/Grid';
import { RemotisGridProps } from './RemotisGrid.types'

const RemotisGrid = (props : RemotisGridProps): JSX.Element => {
  const {children, ...gridProps} = props;

  return (
    <Grid {...gridProps} >
      {children}
    </Grid>
  );
}

export default RemotisGrid;
