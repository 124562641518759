import RemotisButton from 'common/components/remotis-button/RemotisButton'
import './TestNetwork.scss';

const networks = [
  {
    key: 'Internet connection',
    value: 'Active'
  },
  {
    key: 'Connection Method',
    value: 'Local'
  },
  {
    key: 'IP address settings',
    value: 'Auto'
  },
  {
    key: 'IP client',
    value: '192.168.245.27'
  },
  {
    key: 'Mask',
    value: '192.168.245.1'
  },
  {
    key: 'Gateway',
    value: '192.168.245.1'
  },
  {
    key: 'DNS primary',
    value: '0.0.0.0'
  },
  {
    key: 'DNS secondary',
    value: '90:9e:29:41:ce:29'
  },
  {
    key: 'Client MAC',
    value: '90:9e:29:41:ce:29'
  },
  {
    key: 'MTU',
    value: 'Automatic'
  },
  {
    key: 'Server proxy',
    value: 'Unavailable'
  },
  {
    key: 'NAT Type',
    value: 'Type 2'
  },
]

const TestNetwork = () => {
  return (
    <div className="test-network">

      {networks.map(( network, key ) => {
        return (
          <p key={key}>
            <span className="test-network--key"> {network.key} </span>
            <span className="test-network--value"> {network.value} </span>
          </p>
        )
      })}

      <RemotisButton> Start test </RemotisButton>
    </div>
  );
};

export default TestNetwork;
