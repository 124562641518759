import CardBox from 'common/components/card-box/CardBox';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import SubMenu from 'common/components/sub-menu/SubMenu';
import './TrackIntroduction.scss';

const TrackIntroduction = () => {
  return (
    <RemotisGrid container>
      <RemotisGrid item>
        <SubMenu goBackPath="/circuits/z1ring" />
      </RemotisGrid>
      <RemotisGrid container>
        <RemotisGrid item xs={12}>
          <CardBox>
            <div id="track-introduction">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/sUjhKpSOiDQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </CardBox>
        </RemotisGrid>
      </RemotisGrid>
    </RemotisGrid>
  );
};

export default TrackIntroduction;
