import { Button } from '@mui/material';
import BasicModal from 'common/components/basic-modal/BasicModal';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import React from 'react';
import { Link } from 'react-router-dom';
import SettingCard from 'settings/components/setting-card/SettingCard';
import { LogOut} from 'assets/icons/Icons';
import { settingsIndexRoutes } from 'routes';
import { SettingsLayoutProps } from './SettingsLayout.types'

import './SettingsLayout.scss';

const SettingsLayout = () => {

  const [status, setState] = React.useState(false);

  const handleModal = () => {
    setState((prevStatus) => !prevStatus);
  };

  return (
    <div className="settings-layout">

      <RemotisGrid container>

        { settingsIndexRoutes.map((route:SettingsLayoutProps) => {

          return (
            <RemotisGrid key={route.path} item xs={6} xl={4}>
              <Link to={route.path}>
                <SettingCard title={route.title}>
                  {route.icon &&
                    React.createElement(route.icon)
                  }
                </SettingCard>
              </Link>
            </RemotisGrid>
          )
        })}

        <RemotisGrid item xs={6} xl={4}>
          <Button
            variant="solid"
            className="settings-layout__logout"
            disableRipple
            onClick={handleModal}
          >

            <SettingCard title={ 'LOGOUT' }>
              <LogOut />
            </SettingCard>

          </Button>
        </RemotisGrid>

      </RemotisGrid>

      <BasicModal isOpen={status} closeHandle={handleModal} />
    </div>
  );
};

export default SettingsLayout;
