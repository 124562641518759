import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RemotisAccordionProps } from './RemotisAccordion.types'
import './RemotisAccordion.scss'

const RemotisAccordion = ({ id, title, detail }: RemotisAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState<number | null>(0);

  const handleChange = (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : null);
  };

  return (
    <div className="remotis-accordion">
      <Accordion square={true} expanded={expanded === id} onChange={handleChange(id)}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {title}
        </AccordionSummary>

        <AccordionDetails>
          {detail}
        </AccordionDetails>

      </Accordion>
    </div>
  );
};

export default RemotisAccordion;