import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { ThreeColumnsGridProps } from './ThreeColumnsGrid.types'
import './ThreeColumnsGrid.scss';

const ThreeColumnsGrid = ({ left, right, middle }: ThreeColumnsGridProps): JSX.Element => {
  return (
    <div className="three-columns-grid">
      <RemotisGrid container>

        <RemotisGrid item className="three-columns-grid--title" md={4}>
          {left}
        </RemotisGrid>

        <RemotisGrid item md={4}>
          <div className="three-columns-grid--details">
            {middle}
          </div>
        </RemotisGrid>

        <RemotisGrid item md={4} className="three-columns-grid--options">
          {right}
        </RemotisGrid>

      </RemotisGrid>
    </div>
  );
};

export default ThreeColumnsGrid;
