import { useCallback, useEffect, useState } from 'react';
import RemotisTable from 'common/components/remotis-tabel/RemotisTable';

import { ProfileInterface } from './SdpCodecs.types'
import './SdpCodecs.scss';

import {
  parseProfileLevelId,
  profileToString,
  levelToString
} from 'h264-profile-level-id';

const SdpCodecs = () => {
  const [codecs, setCodecs] = useState<(RTCRtpCodecCapability[])>([]);

  const extractProfileIdFromString = (text: string | number | undefined) => {
    const match = String(text)?.match(/(?<=profile-level-id=).{6}/);
    return match && match[0] ? match[0] : ''
  };

  const convertProfileLevelIdToVariableName = useCallback((text: string | undefined | number) => {
    const profileHexCode = extractProfileIdFromString(text);
    const profileLevelId:ProfileInterface | null = parseProfileLevelId(profileHexCode);
    if(profileLevelId) {
      const profile = profileToString(profileLevelId?.profile)
      const level= levelToString(profileLevelId?.level)
      if(profile || level) {
        return `;profile=${profile};level=${level}`
      }
    }
    return '';
  },[])

  useEffect(() => {
    if(RTCRtpReceiver !== null && RTCRtpReceiver?.getCapabilities('video')) {
      const codecs = RTCRtpReceiver?.getCapabilities('video')?.codecs;
      if(codecs) {
        const formattedCodec = codecs.map(el => el?.sdpFmtpLine ? {...el, sdpFmtpLine: el?.sdpFmtpLine + convertProfileLevelIdToVariableName(el?.sdpFmtpLine)} : el);
        setCodecs(formattedCodec);
      }
    }
  }, [convertProfileLevelIdToVariableName]);

  return (
    <div className="sdpCodecs" id="sdpCodecs">
      <RemotisTable
        data={codecs}
        header={[{title: 'MIME Type', id:'mimeType'}, {title: 'Format-Specific Parameters', id:'sdpFmtpLine'}]}>
      </RemotisTable>
    </div>
  );
};

export default SdpCodecs;
