import './RankingSlot.scss';
import Profile from 'assets/images/Profile.png';
import { RomaniaFlag, LicenseAmateur } from 'assets/icons/Icons';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CardBox from 'common/components/card-box/CardBox';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import SingleRankingSlot from '../single-ranking-slot/SingleRankingSlot';

const RankingSlot = () => {
  const rivals = [
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
    {
      position: '1',
      picture: Profile,
      nationality: RomaniaFlag,
      license: LicenseAmateur,
      name: 'Daniel',
      result: '00:43.23',
    },
  ];
  return (
    <RemotisGrid item>
      <CardBox>
        <div id="ranking-slot">
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">POSITION</TableCell>
                  <TableCell align="center">PICTURE</TableCell>
                  <TableCell align="center">NATIONALITY</TableCell>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">LICENSE</TableCell>
                  <TableCell align="center">RESULTS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rivals.map((rival, key) => (
                  <SingleRankingSlot
                    key={key}
                    position={rival.position}
                    picture={rival.picture}
                    nationality={rival.nationality}
                    name={rival.name}
                    license={rival.license}
                    result={rival.result}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardBox>
    </RemotisGrid>
  );
};

export default RankingSlot;
