import React from 'react';
import './SingleRankingSlot.scss';
import { TableCell, TableRow } from '@mui/material';
import { SingleRankingSlotProps } from './SingleRankingSlot.types';

const SingleRankingSlot = ({
  position,
  picture,
  nationality,
  license,
  name,
  result,
}: SingleRankingSlotProps): JSX.Element => {
  return (
    <TableRow
      className="single-ranking-slot"
      sx={{ '&:last-child td, &:last-child th': { borderBottom: 1 } }}
    >
      <TableCell className="single-ranking-slot__position" align="center">
        {position}
      </TableCell>
      <TableCell align="center">
        <img className="single-ranking-slot__profile-picture" src={picture} alt="ProfilePicture" />
      </TableCell>
      <TableCell align="center">
        <span>{React.createElement(nationality)}</span>
      </TableCell>
      <TableCell className="single-ranking-slot__name" align="center">
        {name}
      </TableCell>
      <TableCell align="center">
        <span>{React.createElement(license)}</span>
      </TableCell>
      <TableCell className="single-ranking-slot__results" align="center">
        {result}
      </TableCell>
    </TableRow>
  );
};

export default SingleRankingSlot;
