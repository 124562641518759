import ErrorPage from 'common/layout/error-page/ErrorPage';


const NotFound = () => {

  return (
    <ErrorPage
      errorCode = "404"
      errorStatus="Not Found"
    />
  );
};

export default NotFound;
