import ElementSubMenu from 'common/components/element-sub-menu/ElementSubMenu';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import SubMenu from 'common/components/sub-menu/SubMenu';
import { Outlet } from 'react-router-dom';
import { CarLayoutProps } from './CarsLayout.types';

const CarsLayout = ({ routes, basePath }: CarLayoutProps): JSX.Element => {
  return (
    <RemotisGrid container className="cars-layout">
      <SubMenu>
        {routes.map((route, key: number) => {
          return (
            <ElementSubMenu path={`${basePath}/${route.path}`} menuTab={route.title} key={key} />
          );
        })}
      </SubMenu>

      <RemotisGrid container>
        <Outlet />
      </RemotisGrid>
    </RemotisGrid>
  );
};

export default CarsLayout;
