// import {RacerBoardProps} from "./RacerBoard.types"
import { SettingsFill } from 'assets/icons/Icons';
import MaterialModal from 'common/components/material-modal/MaterialModal';
import CarSetupLayout from 'common/layout/car-setup/CarSetupLayout';
import React from 'react';
import RemotisButton from 'common/components/remotis-button/RemotisButton';
import './MirrorBoard.scss'
import AudioToggle from 'common/components/audio-toggle/AudioToggle';

const MirrorBoard = (): JSX.Element => {

  const [status, setState] = React.useState(false);

  const handleModal = () => {
    setState((prevStatus) => !prevStatus);
  };

  return (
    <div className="mirror-board">
      <MaterialModal isOpen={status} closeHandle={handleModal}>
        <CarSetupLayout />
      </MaterialModal>
      {/* <div className="mirror-board__text">
        <p>Session Time left: <span>19:35</span></p>
      </div> */}
      <div className="mirror-board--rear-camera__container">
        <video  muted={true} className="mirror-board--rear-camera" id="rearCamera"><track kind="captions"/></video>
      </div>
      <div className="mirror-board__actions">
        <AudioToggle videoId='frontCamera'/>
        <RemotisButton
          variant="solid"
          className="settings-layout__logout"
          disableRipple
          onClick={handleModal}
        >
          <SettingsFill/>
        </RemotisButton>
      </div>
    </div>
  );
};

export default MirrorBoard;