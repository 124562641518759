import { Container, Box } from '@mui/material';
import './RaceFilter.scss';
import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import { useEffect, useState } from 'react';
import { RaceFilterProps } from './RaceFilter.types';

const filters = [
  { name: 'All', class: 'all', id: 'all' },
  { name: 'Free Practice', class: 'fp-dot', id: 'free-practice' },
  { name: 'Single Race', class: 'sr-dot', id: 'single-race' },
  { name: 'Championship', class: 'ch-dot', id: 'championship' },
  { name: 'Event', class: 'ev-dot', id: 'event' },
  { name: 'Test drive', class: 'td-dot', id: 'test-drive' },
];

const RaceFilter = ({ selectedFilter }: RaceFilterProps): JSX.Element => {
  const [selected, setSelected] = useState('all');

  const selectedHandler = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.id;
    setSelected(id);
  };
  useEffect(() => {
    selectedFilter(selected);
  }, [selected]);

  return (
    <div className="race-filter">
      <Container>
        <RemotisGrid container>
          <Box className="race-filter--box">
            <div className="race-filter--box__elements">
              {filters.map((filter, key) => {
                return (
                  <button
                    onClick={selectedHandler}
                    className={`${filter.class} ${
                      filter.id == selected ? 'selected' : 'not-selected'
                    }`}
                    key={key}
                    id={filter.id}
                  >
                    {filter.name}
                  </button>
                );
              })}
            </div>
          </Box>
        </RemotisGrid>
      </Container>
    </div>
  );
};

export default RaceFilter;
