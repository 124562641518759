import RemotisGrid from 'common/components/remotis-grid/RemotisGrid';
import './CalendarSlotsLayout.scss';
import Moment from 'moment';
import SubMenu from 'common/components/sub-menu/SubMenu';
import { useState } from 'react';
import Carousel from 'nuka-carousel';
import RaceFilter from 'racing/components/race-filter/RaceFilter';
import ApiLoader from 'client-server-provider/api-loader/ApiLoader';
import RaceSlotsApi from 'racing/components/race-slots-api/RaceSlotsApi';
import { ApiGetRouteEnum } from 'client-server-provider/api-loader/ApiRouteEnum.enums';

const CalendarSlotsLayout = () => {
  const months = Moment.monthsShort();

  const currentYear = Moment().format('YYYY');
  const currentMonth = Moment().format('MMM');
  const currentDay = Moment().format('DD');

  const [monthSelected, setMonthSelected] = useState(currentMonth);
  const [daySelected, setDaySelected] = useState(currentDay);
  const [filter, setFilter] = useState('');

  const numberMonth = Moment().month(monthSelected).format('MM');
  const numberDay = parseInt(daySelected);

  const monthHandleClick = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.id;
    setMonthSelected(id);
  };

  const dayHandleClick = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.id;
    setDaySelected(id);
  };

  const getFilter = (filter: string) => {
    setFilter(filter);
  };

  const getDaysArray = function (year: string, month: string) {
    const length = Moment(`${year}-${month}`).daysInMonth();
    const days1 = [];
    for (let i = 0; i < length; i++) {
      days1.push({
        day: Moment({ year: parseInt(year), month: parseInt(month) - 1 })
          .add(i, 'days')
          .format('DD'),
        name: Moment({ year: parseInt(year), month: parseInt(month) - 1 })
          .add(i, 'days')
          .format('ddd'),
      });
    }
    return days1;
  };

  const days = getDaysArray(currentYear, numberMonth);

  const getPrevSlide = (current:number) => {
    const prev = current - 7;

    if(prev < 1) {
      return days.length -1 ;
    }

    return prev;

  };

  const getNextSlide = (current:number) => {
    const next = current + 7;
    if(next > days.length) {
      return 1;
    }

    return next;

  };

  const selectedDate = `${currentYear}-${numberMonth}-${daySelected}`;

  const WithRaceSlotsApi = ApiLoader({
    Component: RaceSlotsApi,
    queryKey: ApiGetRouteEnum.getRaceCalendar,
    params: { expand: 'license, raceSessions', start: selectedDate },
    numberDay: numberDay,
    numberMonth: numberMonth,
    filter: filter,
  });

  return (
    <div className="calendar-slots">
      <RaceFilter selectedFilter={getFilter} />
      <RemotisGrid container>
        <RemotisGrid item xs={12}>
          <SubMenu>
            {months.map((month, key) => {
              return (
                <button
                  className={
                    month === monthSelected ? 'calendar-slots--button__month activeBtn' : 'calendar-slots--button__month'
                  }
                  id={month}
                  key={key}
                  onClick={monthHandleClick}
                >
                  {month}
                </button>
              );
            })}
          </SubMenu>
          <Carousel
            renderCenterLeftControls={({goToSlide, currentSlide,  previousDisabled }) => (
              <button onClick={() => {goToSlide(getPrevSlide(currentSlide))}} disabled={previousDisabled}>
                &#8826;
              </button>
            )}
            renderCenterRightControls={({goToSlide, currentSlide }) => (
              <button onClick={() => {goToSlide(getNextSlide(currentSlide))}}
              >
                &#8827;
              </button>
            )}
            slidesToShow={7}
            slideIndex={parseInt(Moment().format('DD')) - 1}
            className="calendar-slots-carousel"
            cellAlign={'center'}
          >
            {days.map((day, key) => {
              return (
                <button
                  key={key}
                  className={day.day === daySelected ? 'calendar-slots--days activeBtn' : 'calendar-slots--days'}
                  onClick={dayHandleClick}
                  id={day.day}
                >
                  <span className="calendar-slots-days__month">{monthSelected}</span>
                  <br></br>
                  <span className="calendar-slots-days__number">{day.day} </span>
                  <br></br>
                  <span className="calendar-slots-days__name">{day.name}</span>
                </button>
              );
            })}
          </Carousel>
          <WithRaceSlotsApi />
        </RemotisGrid>
      </RemotisGrid>
    </div>
  );
};

export default CalendarSlotsLayout;
